import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import useCurrentUser from 'hooks/useCurrentUser';
import type { PromptSectionDraft } from 'services/types/promptSection';

interface Props {
  close: () => void;
  title?: string;
  promptSection: PromptSectionDraft;
  save?: (promptSection: PromptSectionDraft) => void;
}

const PromptSectionModal: FC<Props> = ({ close, title, promptSection, save }) => {
  const currentUser = useCurrentUser();
  const [name, setName] = useState<string>(promptSection.name);
  const [displayName, setDisplayName] = useState<string>(promptSection.displayName);
  const [value, setValue] = useState<string>(promptSection.value);
  const [permission, setPermission] = useState(promptSection.permission);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value.replace(/[^a-zA-Z0-9]/g, '_'));
  };

  return (
    <Modal open onClose={() => close()}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '95%',
        }}
      >
        <Stack spacing={2} p={2}>
          <Typography variant="h5">{title}</Typography>
          <TextField
            value={name}
            onChange={handleNameChange}
            label="Identificador"
            placeholder="(No se puede cambiar una vez creado)"
            disabled={!!promptSection.name}
          />
          <TextField
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            label="Descripción"
            placeholder="Nombre/descripción de este pedazo de información"
          />
          <TextField value={value} onChange={(e) => setValue(e.target.value)} label="Valor" multiline maxRows={10} />
          <Select
            value={permission}
            onChange={(e) => setPermission(e.target.value as 'hidden' | 'read-only' | 'suggest-edit' | 'editable')}
            label="Permiso"
            disabled={!currentUser?.isAdmin}
          >
            <MenuItem value="hidden">Oculto</MenuItem>
            <MenuItem value="read-only">Solo lectura</MenuItem>
            <MenuItem value="suggest-edit">Sugerir edición</MenuItem>
            <MenuItem value="editable">Editable</MenuItem>
          </Select>

          <Box width="100%" display="flex" justifyContent="center">
            <PrimaryBtn
              disabled={!name || !displayName || !value}
              onClick={() => {
                if (save) {
                  save({
                    ...promptSection,
                    name,
                    displayName,
                    value,
                    permission,
                  });
                }
                close();
              }}
            >
              Guardar
            </PrimaryBtn>
          </Box>
        </Stack>
      </Paper>
    </Modal>
  );
};

PromptSectionModal.defaultProps = {
  save: () => {},
  title: 'Editar información',
};

export default PromptSectionModal;
