import * as React from 'react';

import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type HardMetricCardProps = {
  type: string;
  data?: number | null;
  secondaryData?: number;
  tooltip: string;
  percentage?: boolean;
  isBigSize?: boolean;
};

const HardMetricCard: React.FC<HardMetricCardProps> = ({
  type,
  tooltip,
  data,
  secondaryData,
  percentage,
  isBigSize,
}) => {
  let displayData;
  if (data === null || data === undefined || data < 0) {
    displayData = '-';
  } else if (data % 1 !== 0 && !percentage) {
    displayData = data.toFixed(2);
  } else if (percentage) {
    displayData = `${(data * 100).toFixed(1)}%`;
  } else {
    displayData = data;
  }

  return (
    <Tooltip arrow enterDelay={300} title={tooltip} followCursor placement="top-start">
      <span>
        <Card
          sx={{
            width: '100%',
            height: isBigSize ? '230px' : '107px',
            boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)',
            borderRadius: '16px',
          }}
        >
          <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography component="h2" variant="h6" color="primary" fontSize={isBigSize ? '24px' : '16px'}>
              {type}
            </Typography>
            {isBigSize && <br />}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography
                textAlign="center"
                sx={{ color: 'text.secondary', fontSize: isBigSize ? '36px' : '24px', fontWeight: 'medium' }}
              >
                {displayData}{' '}
                {(secondaryData || secondaryData === 0) && (
                  <span style={{ fontSize: isBigSize ? '24px' : '18px' }}>({secondaryData})</span>
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </span>
    </Tooltip>
  );
};

HardMetricCard.defaultProps = {
  percentage: false,
  secondaryData: undefined,
  data: undefined,
  isBigSize: false,
};

export default HardMetricCard;
