import React, { useEffect } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { DataGrid, GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';

import useCurrentUser from 'hooks/useCurrentUser';
import {
  useGetPromptSectionsQuery,
  useCreatePromptSectionMutation,
  useSuggestEditPromptSectionMutation,
} from 'services/api/promptSections.api';
import { PromptSection, PromptSectionDraft } from 'services/types/promptSection';

import PromptSectionModal from './PromptSectionModal';

interface Props {
  businessUnitId: number;
  refetchFlag?: number;
}

const TextAreaDetails = ({ value }: GridRenderEditCellParams<PromptSection, string>): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: 600,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper
            elevation={1}
            sx={{ p: 1, minWidth: 600, minHeight: 150, maxWidth: 1000, boxShadow: '2px 2px 5px 1px' }}
          >
            <Box sx={{ whiteSpace: 'pre-wrap' }}>{value}</Box>
          </Paper>
        </Popper>
      )}
    </div>
  );
};
const PromptSectionsForBusinessUnit = (props: Props) => {
  const { businessUnitId, refetchFlag } = props;
  const { data: promptSections, refetch } = useGetPromptSectionsQuery({ businessUnitId });
  const [selectedPromptSection, setSelectedPromptSection] = React.useState<PromptSection | null>(null);
  const [isSuggestion, setIsSuggestion] = React.useState(false);
  const [createPromptSection, { isSuccess: isSuccessCreate }] = useCreatePromptSectionMutation();
  const [suggestEditPromptSection, { isSuccess: isSuccessSuggestEdit }] = useSuggestEditPromptSectionMutation();

  const currentUser = useCurrentUser();

  const handleCreatePromptSection = (promptSection: PromptSectionDraft) => {
    createPromptSection({
      businessUnitId,
      promptSection,
    }).catch(console.error); // eslint-disable-line no-console
  };

  const handleSuggestEditPromptSection = (promptSection: PromptSectionDraft) => {
    suggestEditPromptSection({
      businessUnitId,
      promptSection: {
        name: promptSection.name,
        displayName: promptSection.displayName,
        value: promptSection.value,
      },
    }).catch(console.error); // eslint-disable-line no-console
  };

  useEffect(() => {
    if (isSuccessCreate) {
      refetch().catch(console.error); // eslint-disable-line no-console
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (refetchFlag) {
      refetch().catch(console.error); // eslint-disable-line no-console
    }
  }, [refetchFlag]);

  useEffect(() => {
    if (isSuccessSuggestEdit) {
      refetch().catch(console.error); // eslint-disable-line no-console
    }
  }, [isSuccessSuggestEdit]);

  const columns: GridColDef<PromptSection>[] = [
    {
      field: 'name',
      width: 200,
      headerName: 'Identificador',
      renderCell: (params: GridRenderCellParams<PromptSection, string>) => {
        if (!currentUser?.isAdmin) {
          return params.value;
        }
        // Return the name and a button with a copy to clipboard functionality
        return (
          <Box display="flex" alignItems="center" height="100%">
            <IconButton
              title="Copiar al portapapeles"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => navigator.clipboard.writeText(`<SECTION:${params.value || ''}>`)} // eslint-disable-line no-console
            >
              <ContentCopyIcon />
            </IconButton>
            {params.row.isUsed === false && <WarningIcon titleAccess="No utilizado" sx={{ mr: 1 }} />}
            <Typography color={params.row.isUsed === false ? 'red' : undefined}>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'displayName',
      headerName: 'Descripción',
      width: 250,
      rowSpanValueGetter: (value: boolean, row: PromptSection) => `${row.name}-${value}`,
      renderCell: (params: GridRenderCellParams<PromptSection, string>) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: 'value',
      headerName: 'Valor (haz doble click para ver más)',
      width: 405,
      rowSpanValueGetter: () => null,
      type: 'string',
      editable: true,
      renderCell: (params: GridRenderCellParams<PromptSection, string>) => {
        const splittedValue = params.value ? params.value.split('\n') : [];
        let text = '';
        splittedValue.slice(0, 2).forEach((line, index) => {
          if (line.length > 40) {
            text += `${line.slice(0, 40)}...\n`;
          } else if (index === 1) {
            text += `${line}...`;
          } else {
            text += `${line}\n`;
          }
        });
        return (
          <Typography
            display="flex"
            flexDirection="column"
            sx={{
              whiteSpace: 'pre-wrap',
              overflow: 'elipsis',
              height: '100%',
              // justifyContent: 'center',
              justifyContent: splittedValue.length === 1 ? 'center' : 'flex-start',
              // fontSize: splittedValue.length > 2 ? '0.8rem' : '1rem',
              // lineHeight: splittedValue.length > 2 ? '1' : '1.5',
              textDecoration: params.row.isLastVersion || params.row.isSuggestion ? 'none' : 'line-through',
              // eslint-disable-next-line no-nested-ternary
              color: params.row.isLastVersion ? 'green' : params.row.isSuggestion ? 'orange' : 'black',
            }}
          >
            {text}
          </Typography>
        );
      },
      renderEditCell: (params) => <TextAreaDetails {...params} />,
    },
    {
      field: 'permission',
      headerName: 'Permiso',
      width: 120,
      rowSpanValueGetter: (value: string, row: PromptSection) => `${row.name}-${row.isLastVersion}-${value}`,
      valueFormatter: (value) =>
        ({ hidden: 'Oculto', 'read-only': 'Solo lectura', 'suggest-edit': 'Sugerir edición', editable: 'Editable' })[
          value
        ],
    },
    {
      field: 'createdAt',
      width: 160,
      headerName: 'Creación',
      type: 'dateTime',
      rowSpanValueGetter: () => null,
      // 24 hour format
      valueFormatter: (value) => new Date(value).toLocaleString('es-CL', { hour12: false }),
    },
    {
      field: 'isLastVersion',
      headerName: '¿Es última versión?',
      width: 150,
      type: 'boolean',
      rowSpanValueGetter: (value: boolean, row: PromptSection) => `${row.name}-${value}`,
    },
    {
      field: 'isSuggestion',
      headerName: '¿Es sugerencia?',
      width: 150,
      type: 'boolean',
      rowSpanValueGetter: (value: boolean, row: PromptSection) => `${row.name}-${value}`,
    },
    {
      field: 'id',
      headerName: 'Acciones',
      width: 110,
      rowSpanValueGetter: (_: number, row: PromptSection) => `${row.name}-${row.isLastVersion}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // params: GridRenderCellParams<any, number>
      renderCell: (params: GridRenderCellParams<PromptSection, number>) => (
        <>
          {params.row.isLastVersion && (currentUser?.isAdmin || params.row.permission !== 'read-only') && (
            <IconButton
              title="Sugerir edición"
              onClick={() => {
                setSelectedPromptSection(params.row);
                setIsSuggestion(true);
              }}
              sx={{ width: '40px' }}
              disabled={
                !params.row.isLastVersion ||
                (currentUser !== null && !currentUser.isAdmin && params.row.permission === 'read-only')
              }
            >
              <RateReviewIcon />
            </IconButton>
          )}
          {params.row.isLastVersion && (currentUser?.isAdmin || params.row.permission === 'editable') && (
            <IconButton
              title="Editar"
              onClick={() => {
                setSelectedPromptSection(params.row);
                setIsSuggestion(false);
              }}
              sx={{ width: '40px' }}
              disabled={
                !params.row.isLastVersion ||
                (currentUser !== null && !currentUser.isAdmin && params.row.permission !== 'editable')
              }
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <Box>
      {selectedPromptSection && (
        <PromptSectionModal
          close={() => setSelectedPromptSection(null)}
          save={isSuggestion ? handleSuggestEditPromptSection : handleCreatePromptSection}
          promptSection={selectedPromptSection}
          title={isSuggestion ? 'Sugerir nuevo valor' : 'Editar'}
        />
      )}
      <Box sx={{ height: 'calc(100vh - 100px)', color: 'black' }}>
        {promptSections && (
          <DataGrid
            disableRowSelectionOnClick
            showCellVerticalBorder
            unstable_rowSpanning
            rows={promptSections}
            columns={columns}
          />
        )}
      </Box>
    </Box>
  );
};

PromptSectionsForBusinessUnit.defaultProps = {
  refetchFlag: 0,
};

export default PromptSectionsForBusinessUnit;
