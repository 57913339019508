import React, { FC, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useGetUserBusinessUnitsQuery } from 'services/api/user.api';

interface Props {
  businessUnitId: number | null;
  setBusinessUnitId: (businessUnitId: number) => void;
  disableAllBusinessUnitsOption?: boolean;
  selectItemIfOnlyOne?: boolean;
}

const BusinessUnitSelector: FC<Props> = ({
  businessUnitId,
  setBusinessUnitId,
  disableAllBusinessUnitsOption,
  selectItemIfOnlyOne,
}) => {
  const { data: businessUnits } = useGetUserBusinessUnitsQuery(null);

  useEffect(() => {
    if (selectItemIfOnlyOne && businessUnits && businessUnits.length === 1) {
      setBusinessUnitId(businessUnits[0].id);
    }
  }, [businessUnits, selectItemIfOnlyOne, setBusinessUnitId]);

  if (!businessUnits) {
    return null;
  }

  const businessUnitByCompany = businessUnits.reduce(
    (acc, businessUnit) => {
      const company = businessUnit.company?.name;
      if (!acc[company]) {
        acc[company] = [];
      }
      acc[company].push(businessUnit);
      return acc;
    },
    {} as Record<string, typeof businessUnits>
  );
  const menuItems = Object.keys(businessUnitByCompany)
    .sort((a, b) => a.localeCompare(b))
    .map((company) => {
      // Need array render because you cant use a fragment inside a select
      return [
        <ListSubheader key={company} sx={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
          <Typography fontWeight={500}>{company}</Typography>
        </ListSubheader>,
        businessUnitByCompany[company].map((businessUnit) => (
          <MenuItem key={businessUnit.id} value={businessUnit.id}>
            <Typography fontWeight={500}>{businessUnit.name}</Typography>
          </MenuItem>
        )),
      ];
    });

  return (
    <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
      <InputLabel id="businessUnit-select-label">Unidad de negocio</InputLabel>
      <Select
        label="Unidad de negocio"
        variant="outlined"
        onChange={(e) => setBusinessUnitId(Number(e.target.value))}
        value={businessUnitId || ''}
        autoWidth
      >
        {!disableAllBusinessUnitsOption && <MenuItem value="-1">Todas las unidades de negocio</MenuItem>}
        {menuItems}
      </Select>
    </FormControl>
  );
};

BusinessUnitSelector.defaultProps = {
  disableAllBusinessUnitsOption: false,
  selectItemIfOnlyOne: false,
};

export default BusinessUnitSelector;
