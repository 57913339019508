import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { PrimaryBtn } from 'components/UI/Buttons';

const Navigation = () => {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar
      sx={{
        ml: 10,
        variant: 'h2',
        backgroundColor: scrollPosition < 10 ? 'transparent' : '#15183A',
        boxShadow: 'none',
      }}
    >
      <Container>
        <Box my={1} display="flex" justifyContent="space-between" alignItems="center" sx={{ mx: 'auto' }}>
          <Box sx={{ cursor: 'pointer' }}>
            <img
              src="/zeller-logo-blanco.svg"
              alt="Logo"
              style={{
                minWidth: '150px',
              }}
            />
          </Box>
          <Box justifyContent="center" alignItems="center" gap={2} display="flex">
            <PrimaryBtn
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: 'transparent',
                borderColor: '#F9D00A',
                '&:hover': {
                  backgroundColor: '#F9D00A',
                  borderColor: '#F9D00A',
                  color: '#15183A',
                },
              }}
              onClick={() => navigate('/sign-in')}
            >
              Iniciar sesión
            </PrimaryBtn>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Navigation;
