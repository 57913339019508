import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';

import NewPromptSectionButton from './components/NewPromptSectionButton';
import PromptSectionsForBusinessUnit from './components/PromptSectionsForBusinessUnit';

const PromptSections = () => {
  const [businessUnitId, setBusinessUnitId] = React.useState<number | null>(null);
  const [refetchFlag, setRefetchFlag] = React.useState<number>(0);
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <Box>
          <Typography variant="h4">Información que contiene Zeller</Typography>
          <Typography variant="subtitle1">
            (Por detrás hay más información disponible no visible en esta tabla)
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {businessUnitId && (
            <NewPromptSectionButton businessUnitId={businessUnitId} refetch={() => setRefetchFlag(refetchFlag + 1)} />
          )}
          <BusinessUnitSelector
            businessUnitId={businessUnitId}
            setBusinessUnitId={setBusinessUnitId}
            disableAllBusinessUnitsOption
            selectItemIfOnlyOne
          />
        </Box>
      </Box>
      <Box m={2}>
        {businessUnitId && <PromptSectionsForBusinessUnit refetchFlag={refetchFlag} businessUnitId={businessUnitId} />}
      </Box>
      {!businessUnitId && (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <Typography variant="h5">
            Selecciona una unidad de negocio para ver la información que Zeller tiene disponible
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PromptSections;
