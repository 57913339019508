import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { Operator, WhereFilter } from 'services/types/analysis';

interface Props {
  whereFilters: WhereFilter[];
}

const operatorToText = (operator: Operator) => {
  switch (operator) {
    case 'greaterThan':
      return '>';
    case 'equals':
    case 'is':
      return '=';
    case 'notEquals':
    case 'isNot':
      return '!=';
    case 'lessThan':
      return '<';
    case 'contains':
      return 'contiene';
    case 'startsWith':
      return 'empieza con';
    case 'endsWith':
      return 'termina con';
    case 'in':
      return 'en';
    default:
      return operator;
  }
};

const WhereFiltersComponent: FC<Props> = ({ whereFilters }: Props) => {
  if (whereFilters?.length === 0) {
    return null;
  }
  return (
    <>
      <Typography>Filtros:</Typography>
      {whereFilters?.map((filter) => (
        <Box key={`${filter.field}-${filter.value}`} flexDirection="row">
          <Typography variant="body1">
            - {filter.field} {operatorToText(filter.operator)} {filter.value}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default WhereFiltersComponent;
