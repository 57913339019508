import React, { FC } from 'react';

import { Box, Paper, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title?: string;
  actionBtn?: React.ReactNode | null;
  sx?: object;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const PaperContainer: FC<Props> = ({ children, title, actionBtn, fullWidth, fullHeight, sx }) => {
  return (
    <Paper
      sx={{
        width: fullWidth ? '100%' : { xs: '100%', md: '80%', lg: '60%' },
        height: fullHeight ? '100%' : 'auto',
        minHeight: '150px',
        borderRadius: '20px',
        py: 3,
        px: { xs: 2, lg: 4 },
        my: 2,
        overflowX: 'auto',
        ...sx,
      }}
    >
      {title || actionBtn ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h4" mb={{ xs: 2, lg: 0 }} textAlign="left">
            {title}
          </Typography>
          {actionBtn && <Box mx={1}>{actionBtn}</Box>}
        </Box>
      ) : null}
      {children}
    </Paper>
  );
};

PaperContainer.defaultProps = {
  sx: {},
  title: '',
  actionBtn: null,
  fullWidth: false,
  fullHeight: false,
};

export default PaperContainer;
