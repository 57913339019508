import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Header = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url("/landing/headerBackground.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        py: { xl: '250px', lg: '120px', xs: '180px' },
        pt: { xs: '100px' },
      }}
    >
      <Box display="flex" mx="auto" justifyContent="center" flexDirection={{ lg: 'row', xs: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            width: { lg: '50%', xs: '100%' },
            ml: { lg: 4 },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline',
              maxWidth: { lg: '80%', xs: '90%' },
              margin: '0 auto',
            }}
          >
            <Typography
              sx={{
                mt: { lg: 0, xs: 8 },
                mb: 3,
                fontSize: { lg: 62, xs: 50 },
                lineHeight: { lg: '60px', xs: '47px' },
                fontWeight: 'medium',
                color: 'white',
                textAlign: { lg: 'left', xs: 'center' },
              }}
            >
              <span style={{ color: '#47BCD8', fontWeight: 'bold' }}>Automatiza</span>
              <br />
              tus ventas automotrices
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: 22, xs: 18 },
                fontFamily: 'Jost',
                fontWeight: 'regular',
                color: 'white',
                lineHeight: '25px',
                paddingRight: { lg: '120px', xs: '0px' },
                textAlign: { lg: 'left', xs: 'center' },
              }}
            >
              Atiende en el minuto a tus prospectos con AI, mejorando concretamente tu proceso de venta.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, pb: { xs: 0, lg: 12 }, justifyContent: 'center', display: 'flex' }}>
          <img alt="" src="/landing/autoConMensajes.png" style={{ width: 'auto', maxWidth: '100%' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
