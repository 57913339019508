import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { PrimaryBtn } from 'components/UI/Buttons';
import Conversation from 'components/common/Conversation';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetClientQuery, useUpdateAdminEditableAttributesMutation } from 'services/api/clients.api';
import { useGetClientMessagesQuery } from 'services/api/messages';
import { useGetBusinessUnitByIdMutation } from 'services/api/user.api';
import priceFormat from 'services/format/priceFormat';
import { EditableAdminClientAttributes } from 'services/types/client';
import { Message } from 'services/types/message';

import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import EditableAttribute from './EditableAttribute';

const Chat = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { data: client, refetch: refetchClient, isLoading } = useGetClientQuery(Number(clientId));
  const { data: messages, refetch: refetchMessages } = useGetClientMessagesQuery(Number(clientId));
  const [getBusinessUnitById, { data: clientDealership }] = useGetBusinessUnitByIdMutation();
  const [updateClient, { data: updatedClient }] = useUpdateAdminEditableAttributesMutation();

  const [clientDraft, setClientDraft] = useState<EditableAdminClientAttributes | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [isFilteredConversation, setIsFilteredConversation] = useState<boolean>(
    currentUser?.filterConversationsByDefault || false
  );

  const isCarDealershipAssistant = clientDealership?.type === 'car_dealership';

  useEffect(() => {
    if (messages) {
      setConversation(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (client) {
      setClientDraft({
        firstName: client.firstName,
        lastName: client.lastName,
        hasValidName: client.hasValidName,
        rut: client.rut || undefined,
        email: client.email,
        salary: client.salary,
        savingsAmount: client.savingsAmount || undefined,
        hasVehicleAsPartPayment: client.hasVehicleAsPartPayment,
        requiresFinancing: client.requiresFinancing,
        monthlyPaymentsAmount: client.monthlyPaymentsAmount || undefined,
        budgetAmount: client.budgetAmount || undefined,
        modelsOfInterest: client.modelsOfInterest || [],
        disabledAutomaticResponses: client.disabledAutomaticResponses || false,
        disabledFollowUps: client.disabledFollowUps || false,
        isTestClient: client.isTestClient || false,
        brandOfInterest: client.brandOfInterest || undefined,
        aiSafetyStatus: client.aiSafetyStatus || undefined,
      });
      getBusinessUnitById(client.businessUnitId).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [client]);

  useEffect(() => {
    if (updatedClient) {
      refetchClient().catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [updatedClient, refetchClient]);

  const handleEditClient = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClient = () => {
    if (clientDraft && client) {
      updateClient({ id: client.id, body: clientDraft }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
      setIsEditing(false);
    }
  };

  const handleRefetchClient = () => {
    // eslint-disable-next-line no-console
    refetchClient().catch((err) => console.error(err));
    // eslint-disable-next-line no-console
    refetchMessages().catch((err) => console.error(err));
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!client) {
    return <div>Cliente no encontrado</div>;
  }

  return (
    <Grid container>
      <Grid item xs={4} borderRight="1px solid #e0e0e0" height="100vh" display="inline-grid">
        <Box height="80px">
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}
            p={2}
          >
            <PrimaryBtn onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />} variant="contained">
              Volver
            </PrimaryBtn>
            {currentUser?.isAdmin && (
              <PrimaryBtn onClick={handleEditClient} startIcon={<EditIcon />} variant="contained">
                {isEditing ? 'Cancelar' : 'Editar'}
              </PrimaryBtn>
            )}
          </Grid>
          <Divider />
        </Box>
        <Box overflow="auto" height="100%">
          <Grid item px={1} pt={1}>
            <b>Identificador:</b> {client.id} {currentUser?.isAdmin && `(${client.businessUnit?.name})`}
          </Grid>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {clientDraft?.hasValidName && <CheckIcon color="success" />}
            <EditableAttribute
              label="Nombre"
              value={clientDraft?.firstName || ''}
              onChange={(e) => setClientDraft({ ...clientDraft, firstName: e.target.value })}
              disabled={!isEditing}
              type="text"
            />
          </Box>
          <EditableAttribute
            label="Apellido"
            value={clientDraft?.lastName || ''}
            onChange={(e) => setClientDraft({ ...clientDraft, lastName: e.target.value })}
            disabled={!isEditing}
            type="text"
          />
          <EditableAttribute
            label="Email"
            value={clientDraft?.email || ''}
            onChange={(e) => setClientDraft({ ...clientDraft, email: e.target.value })}
            disabled={!isEditing}
            type="email"
          />
          <EditableAttribute
            label="Rut"
            value={clientDraft?.rut || ''}
            onChange={(e) => setClientDraft({ ...clientDraft, rut: e.target.value })}
            disabled={!isEditing}
            type="text"
          />
          <Grid item px={1} pt={1}>
            <b>Teléfono:</b> {client.phone}
          </Grid>
          <EditableAttribute
            label="Sueldo"
            value={clientDraft?.salary?.toString() || ''}
            onChange={(e) => setClientDraft({ ...clientDraft, salary: Number(e.target.value) })}
            disabled={!isEditing}
            type="price"
          />
          <EditableAttribute
            label="Pie"
            value={clientDraft?.savingsAmount?.toString() || ''}
            onChange={(e) => setClientDraft({ ...clientDraft, savingsAmount: Number(e.target.value) })}
            disabled={!isEditing}
            type="price"
          />
          <Grid item px={1} pt={1}>
            <b>Mensaje inicial:</b> {client.prospectMessage}
          </Grid>
          <Grid item px={1} pt={1}>
            <b>Fuente:</b> {client.source}
          </Grid>
          {isCarDealershipAssistant && (
            <>
              <EditableAttribute
                label="¿Vehículo en parte de pago?"
                value={!!clientDraft?.hasVehicleAsPartPayment}
                onChange={(e) => setClientDraft({ ...clientDraft, hasVehicleAsPartPayment: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              <EditableAttribute
                label="¿Requiere financiamiento?"
                value={!!clientDraft?.requiresFinancing}
                onChange={(e) => setClientDraft({ ...clientDraft, requiresFinancing: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              <EditableAttribute
                label="Modelos de interés"
                value={clientDraft?.modelsOfInterest?.join(', ') || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, modelsOfInterest: e.target.value.split(', ') })}
                disabled={!isEditing}
                type="text"
              />
              <EditableAttribute
                label="Marca de interés"
                value={clientDraft?.brandOfInterest || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, brandOfInterest: e.target.value })}
                disabled={!isEditing}
                type="text"
              />
              <EditableAttribute
                label="Presupuesto"
                value={clientDraft?.budgetAmount || 0}
                onChange={(e) => setClientDraft({ ...clientDraft, budgetAmount: Number(e.target.value) })}
                disabled={!isEditing}
                type="price"
              />
              <Grid item px={1} pt={1}>
                <b>Fecha de visita:</b> {client.visitDate ? new Date(client.visitDate).toLocaleString('es-CL') : 'No'}
              </Grid>
              <Grid item px={1} pt={1}>
                <b>Estado de crédito:</b> {client.creditStatus}
              </Grid>
            </>
          )}

          {currentUser?.isAdmin && (
            <>
              <EditableAttribute
                label="Desactivar respuestas automáticas"
                value={!!clientDraft?.disabledAutomaticResponses}
                onChange={(e) => setClientDraft({ ...clientDraft, disabledAutomaticResponses: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              <EditableAttribute
                label="Follow ups desactivados"
                value={!!clientDraft?.disabledFollowUps}
                onChange={(e) => setClientDraft({ ...clientDraft, disabledFollowUps: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              <EditableAttribute
                label="Cliente de prueba"
                value={!!clientDraft?.isTestClient}
                onChange={(e) => setClientDraft({ ...clientDraft, isTestClient: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              {client.branchOffice && (
                <Grid item px={1} pt={1}>
                  <b>Sucursal:</b> {client.branchOffice}
                </Grid>
              )}
              <Grid item px={1} pt={1}>
                <b>Subido a CRM:</b> {client.lastCRMUpdate !== null ? 'Si' : 'No'}
              </Grid>
              <Grid item px={1} pt={1}>
                <b>Última actualización en CRM:</b>{' '}
                {client.lastCRMUpdate ? new Date(client.lastCRMUpdate).toLocaleString('es-CL') : '-'}
              </Grid>
              <Grid item px={1} pt={1}>
                <b>Temas hablados:</b> {client.topics?.join(', ')}
              </Grid>
              <Grid item px={1} pt={1}>
                <b>Motivo de término:</b> {client.endReason}
              </Grid>
              <Grid item px={1} pt={1}>
                <b>Revisión chat:</b> {client.AIRevision}
              </Grid>
              {!isFilteredConversation && (
                <>
                  <Grid item px={1} pt={1}>
                    <b>Costo Total:</b> {priceFormat.format(client.cost || 0)}
                  </Grid>
                  <EditableAttribute
                    label="Safety Status (safe, unsafe or force-always-safe)"
                    value={clientDraft?.aiSafetyStatus || ''}
                    onChange={(e) => setClientDraft({ ...clientDraft, aiSafetyStatus: e.target.value })}
                    disabled={!isEditing}
                    type="text"
                  />
                </>
              )}
            </>
          )}
          {isEditing && (
            <Grid item xs={12} px={1} pt={1}>
              <PrimaryBtn onClick={handleSaveClient} variant="contained">
                Guardar
              </PrimaryBtn>
            </Grid>
          )}
          {client.surveys?.map((survey) => (
            <Grid item px={1} pt={1} key={survey.name}>
              <b>Resultado encuesta:</b> {survey.name} ({survey.status === 'finished' ? '✅' : 'Sin terminar'})
              {survey.results.map((result, index) => (
                <Grid item pl={1} key={result.question}>
                  {index + 1}. {result.question}: <b>{result.response || '-'}</b>
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          backgroundImage: 'url("/whatsapp-background.png")',
          backgroundColor: '#efeae2',
          backgroundRepeat: 'repeat',
          backgroundSize: '412.5px 749.25px',
          height: '100vh',
          display: 'grid',
        }}
      >
        <Box alignSelf="start">
          <ChatHeader
            client={client}
            refetch={handleRefetchClient}
            setIsFilteredConversation={setIsFilteredConversation}
            isFilteredConversation={isFilteredConversation}
          />
          <Divider sx={{ height: 0 }} />
        </Box>
        {conversation.length > 0 && (
          <Box overflow="auto" height="100%">
            <Conversation conversation={conversation} isFilteredConversation={isFilteredConversation} fullHeight />
          </Box>
        )}
        <Box alignSelf="end">
          <ChatFooter client={client} conversation={conversation} setConversation={setConversation} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Chat;
