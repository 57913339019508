import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useCreateProspectMutation } from 'services/api/prospects.api';
import validateEmail from 'utils/validations/validateEmail';

import { BlueSeparator } from './How';

const FooterContacts = (title: string, content: string) => (
  <Box px={{ xs: 5, lg: 0 }} pb={{ xs: 2, lg: 0 }}>
    <Box width="min-content" display="inline-grid">
      <Typography
        fontFamily="Roboto"
        fontSize={{ lg: '15px', xs: '20px' }}
        lineHeight="30px"
        fontWeight="600"
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <BlueSeparator />
    </Box>
    <Typography fontFamily="Jost" fontSize={{ lg: '15px', xs: '20px' }} lineHeight="30px" fontWeight="regular">
      {content}
    </Typography>
  </Box>
);

const Footer: FC = () => {
  const [email, setEmail] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [phone, setPhone] = React.useState('');

  const [createProspect] = useCreateProspectMutation();

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setInvalidEmail(true);
    }
    await createProspect({ email, phone, reason: 'Landing page lead' });
  };

  return (
    <Grid pt={8}>
      <Box
        sx={{
          backgroundImage: {
            xs: 'url("/landing/footer-background-mobile.svg")',
            lg: 'url("/landing/footer-background.svg")',
          },
          position: 'relative',
          zIndex: 1,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        display="flex"
        justifyContent="center"
        flexDirection={{ lg: 'row', xs: 'column' }}
        py={10}
      >
        <Box textAlign={{ xs: 'center', lg: 'start' }}>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="medium"
            color="white"
          >
            ¿Quieres saber más?
          </Typography>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="600"
            color="#47BCD8"
          >
            Hablemos!
          </Typography>
        </Box>
        <Box pl={{ xs: 4, sm: 12, lg: 20 }} pr={{ xs: 4, sm: 12, lg: 0 }} pt={{ xs: 2, lg: 0 }}>
          <TextField
            placeholder="Tu correo"
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white', borderRadius: '6px' }}
            onChange={(e) => setEmail(e.target.value)}
            error={invalidEmail}
            helperText={invalidEmail && 'Por favor ingrese un email válido'}
          />
          <TextField
            placeholder="Tu Celular"
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white', borderRadius: '6px', my: 2 }}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Box display="flex" justifyContent={{ xs: 'center', lg: 'end' }}>
            <PrimaryBtn
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: 'transparent',
                borderColor: '#F9D00A',
                '&:hover': {
                  backgroundColor: '#F9D00A',
                  borderColor: '#F9D00A',
                  color: '#15183A',
                },
              }}
              onClick={() => {
                // eslint-disable-next-line no-console
                handleSubmit().catch((e) => console.error(e));
              }}
            >
              Enviar
            </PrimaryBtn>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        justifyContent="space-around"
        textAlign={{ xs: 'center', lg: 'start' }}
        sx={{ backgroundColor: '#F1F6FC' }}
        py={{ xs: 4, lg: 8 }}
      >
        <Typography
          fontFamily="Roboto"
          fontSize={{ lg: '22px', xs: '20px' }}
          lineHeight="26px"
          fontWeight="600"
          textTransform="uppercase"
          px={{ xs: 5, lg: 0 }}
          pb={{ xs: 4, lg: 0 }}
        >
          Contacto
        </Typography>
        {FooterContacts('Dirección', 'Av. Vitacura 4380, piso 2, Vitacura, Santiago')}
        {FooterContacts('Teléfono', '+56 9 9226 8245')}
        {FooterContacts('Correo', 'contacto@zeller.ai')}
      </Box>
    </Grid>
  );
};

export default Footer;
