import { createTheme } from '@mui/material/styles';

// https://colorhunt.co/palette/6499e99eddffa6f6ffbefff7
// Another option https://colorhunt.co/palette/ebe4d1b4b4b326577ce55604
// Test themes here: https://bareynol.github.io/mui-theme-creator
// To find palettes https://m2.material.io/inline-tools/color/

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#6499E9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9EDDFF',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "'Jost','Roboto','sans-serif'",
  },
});

export default defaultTheme;
