import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { RefreshIcon } from 'assets/icons';

interface Props {
  companyIdentifier: string | undefined;
  includeGoToNewChatButton?: boolean;
  children: JSX.Element;
}

const ChatWrapper: React.FC<Props> = ({ companyIdentifier, children, includeGoToNewChatButton }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: { xs: '93vh', md: '100vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#007e69',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex', // flex
          flexDirection: 'column',
          width: { xs: '100%', md: '60%' },
          flex: 1,
          pt: 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 10, alignItems: 'center' }}>
            <Typography color="white" variant="h6" textAlign="center">
              {companyIdentifier?.replaceAll('-', ' ').toUpperCase()}
            </Typography>
            <Typography color="white" variant="body1" textAlign="center">
              En línea
            </Typography>
          </Box>
          {includeGoToNewChatButton && (
            <Box sx={{ flex: 1 }}>
              <IconButton onClick={() => navigate(`/demochat/${companyIdentifier}/newchat`)}>
                <RefreshIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage: 'url("/whatsapp-background.png")',
          backgroundColor: '#efeae2',
          backgroundRepeat: 'repeat',
          backgroundSize: '412.5px 749.25px',
          width: { xs: '100%', md: '60%' },
          flex: 11,
          overflow: 'scroll',
        }}
      >
        <Box height="100%">{children}</Box>
      </Box>
    </Box>
  );
};

// Add the default props
ChatWrapper.defaultProps = {
  includeGoToNewChatButton: false,
};

export default ChatWrapper;
