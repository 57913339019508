import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { EditIcon } from 'assets/icons';
import { PrimaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';

const ColorBox = ({ color, textColor = 'white' }: { color: string; textColor?: string }) => (
  <Box
    sx={{
      bgcolor: color,
      height: '170px',
      width: '170px',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      color: textColor,
    }}
  >
    {color}
  </Box>
);

ColorBox.defaultProps = {
  textColor: 'white',
};

const ThemeDemo = () => {
  return (
    <Box mt={3}>
      <Typography variant="h4" color="initial">
        Botones
      </Typography>
      <Typography variant="h5" color="initial">
        Contained
      </Typography>
      <Box display="flex" justifyContent="space-around" pr={10}>
        <Button>Default</Button>
        <Button variant="contained" color="primary">
          Primary
        </Button>
        <Button variant="contained" color="secondary">
          Secondary
        </Button>
        <Button variant="contained" disabled>
          Disabled
        </Button>
        <PrimaryBtn variant="contained">PrimaryBtn</PrimaryBtn>
        <PrimaryBtn variant="contained" color="secondary">
          PrimaryBtn
        </PrimaryBtn>
      </Box>

      <Typography variant="h5" color="initial">
        Text Buttons
      </Typography>
      <Box display="flex" justifyContent="space-around" pr={10}>
        <Button variant="text">Default</Button>
        <Button variant="text" color="primary">
          Primary
        </Button>
        <Button variant="text" color="secondary">
          Secondary
        </Button>
        <Button variant="text" disabled>
          Disabled
        </Button>
      </Box>

      <Typography variant="h5" color="initial">
        Outlined
      </Typography>
      <Box display="flex" justifyContent="space-around" pr={10}>
        <Button variant="outlined">Default</Button>
        <Button variant="outlined" color="primary">
          Primary
        </Button>
        <Button variant="outlined" color="secondary">
          Secondary
        </Button>
        <Button variant="outlined" disabled>
          Disabled
        </Button>
        <PrimaryBtn variant="outlined">PrimaryBtn</PrimaryBtn>
        <PrimaryBtn variant="outlined" color="secondary">
          PrimaryBtn
        </PrimaryBtn>
      </Box>

      <Typography variant="h4" color="initial">
        Paleta Principal
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="primary.dark" />
        <ColorBox color="primary.main" />
        <ColorBox color="primary.light" textColor="black" />
        <ColorBox color="primary.contrastText" textColor="black" />
      </Box>
      <Typography variant="h4" color="initial">
        Paleta Secundaria
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="secondary.dark" textColor="black" />
        <ColorBox color="secondary.main" textColor="black" />
        <ColorBox color="secondary.light" textColor="black" />
        <ColorBox color="secondary.contrastText" />
      </Box>
      <Typography variant="h4" color="initial">
        Otras Paletas
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="success.main" />
        <ColorBox color="success.light" textColor="black" />
        <ColorBox color="error.main" />
        <ColorBox color="error.light" textColor="black" />
        <ColorBox color="warning.main" />
        <ColorBox color="warning.light" textColor="black" />
        <ColorBox color="info.main" textColor="black" />
        <ColorBox color="info.light" textColor="black" />
      </Box>
      <Typography variant="h4" color="initial">
        Textos
      </Typography>
      <Typography variant="h1">Headline 1</Typography>
      <Typography variant="h2">Headline 2</Typography>
      <Typography variant="h3">Headline 3</Typography>
      <Typography variant="h4">Headline 4</Typography>
      <Typography variant="h5">Headline 5</Typography>
      <Typography variant="subtitle1">Subtitle 1</Typography>
      <Typography variant="subtitle2">Subtitle 2</Typography>
      <Typography variant="body1">Body 1</Typography>
      <Typography variant="body2">Body 2</Typography>
      <Typography variant="button">Button</Typography>
      <br />
      <Typography variant="caption">Caption</Typography>
      <br />
      <Typography variant="overline">Overline</Typography>
      <br />
      <Typography variant="h4" color="initial">
        Contenedores
      </Typography>
      <PaperContainer
        title="PaperContainer"
        actionBtn={
          <Button>
            <EditIcon />
          </Button>
        }
      >
        <Typography variant="body1">Contenido</Typography>
      </PaperContainer>
    </Box>
  );
};

export default ThemeDemo;
