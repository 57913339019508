import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const PrimaryBtn = styled(Button)<ButtonProps>(() => ({
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  paddingRight: '12px',
}));

// eslint-disable-next-line import/prefer-default-export
export { PrimaryBtn };
