import { BusinessUnit } from 'services/types/businessUnit';

import { SignInBody, SignInResponse, User } from '../types/user';
import baseApi from './baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signInUser: build.mutation({
      query: (body: SignInBody) => ({
        url: '/auth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    getUser: build.query({
      query: () => ({
        url: '/users/user',
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
    getUserBusinessUnits: build.query({
      query: () => ({
        url: '/user/businessUnits',
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit[]): BusinessUnit[] => response,
    }),
    getBusinessUnitById: build.mutation({
      query: (businessUnitId: number) => ({
        url: `/user/businessUnit/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit): BusinessUnit => response,
    }),
    getBusinessUnitSources: build.query({
      query: (props: { businessUnitId: number | null }) => ({
        url: `/user/businessUnit/sources?businessUnitId=${props.businessUnitId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    getBusinessUnitBrands: build.query({
      query: (props: { businessUnitId: number | null }) => ({
        url: `/user/businessUnit/brands?businessUnitId=${props.businessUnitId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    updatePassword: build.mutation({
      query: (body: { currentPassword: string; newPassword: string }) => ({
        url: '/user/update-password',
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInUserMutation,
  useGetUserQuery,
  useGetUserBusinessUnitsQuery,
  useGetBusinessUnitByIdMutation,
  useGetBusinessUnitSourcesQuery,
  useUpdatePasswordMutation,
  useGetBusinessUnitBrandsQuery,
} = userApi;

export default userApi;
