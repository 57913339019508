import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Conversations = () => {
  return (
    <Box>
      <Grid container justifyContent="center" spacing={{ xs: 1, lg: 8 }} py={{ lg: 20, xs: 8 }}>
        <Grid item xs={12} lg={1} display="flex" justifyContent={{ xs: 'center' }} alignSelf="start">
          <img alt="" src="/landing/chat-bubble.svg" />
        </Grid>
        <Grid item>
          <Typography
            fontFamily="Roboto"
            fontWeight="medium"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            textAlign="center"
          >
            <span style={{ color: '#2C5889' }}>Conversaciones</span>
            <br />
            humanas
          </Typography>
          <Typography
            fontFamily="Jost"
            fontWeight="regular"
            fontSize={{ lg: '22px', xs: '18px' }}
            lineHeight="25px"
            pt={{ lg: 4, xs: 2 }}
          >
            Usamos AI para hablar como humanos, <br />
            pero con las ventajas de ser un robot.
          </Typography>
        </Grid>
        <Grid item alignSelf="end" display={{ xs: 'none', lg: 'block' }}>
          <img alt="" src="/landing/chat-bubble.svg" style={{ width: '100%' }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={8} pb={8}>
        <Grid item>
          <img alt="" src="/landing/conversation24-7.svg" style={{ width: '100%' }} />
        </Grid>
        <Grid item>
          <img alt="" src="/landing/conversation-personal.svg" style={{ width: '100%' }} />
        </Grid>
        <Grid item>
          <img alt="" src="/landing/conversation-auto.svg" style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Conversations;
