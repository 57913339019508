import React from 'react';

export default function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    }
  });

  return { isMobile };
}
