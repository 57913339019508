import React from 'react';

import Box from '@mui/material/Box';

import Conversations from './components/Conversations';
import Features from './components/Features';
import Footer from './components/Footer';
import Header from './components/Header';
import How from './components/How';
import Included from './components/Included';
import Navigation from './components/Navigation';

const App = () => {
  return (
    <Box sx={{ backgroundColor: '#F1F6FC' }}>
      <Navigation />
      <Header />
      <Conversations />
      <Features />
      <Included />
      <How />
      <Footer />
    </Box>
  );
};

export default App;
