import React from 'react';

import { Grid, TextField } from '@mui/material';

import priceFormat from 'services/format/priceFormat';

interface EditableAttributeProps {
  label: string;
  value: string | boolean | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  type: 'text' | 'price' | 'number' | 'email' | 'checkbox';
}

const EditableAttribute = ({ label, value, onChange, disabled, type }: EditableAttributeProps) => (
  <Grid item xs={12} px={1} pt={1}>
    {disabled && (
      <>
        <b>{label}:</b> {type === 'price' && priceFormat.format(Number(value))}
        {type === 'number' && Number(value)}
        {type === 'checkbox' && (value ? 'Si' : 'No')}
        {type !== 'price' && type !== 'number' && type !== 'checkbox' && value}
      </>
    )}
    {!disabled && type !== 'checkbox' && (
      <TextField label={label} value={value} onChange={onChange} type={type} fullWidth />
    )}
    {!disabled && type === 'checkbox' && (
      <>
        <b>{label}:</b>
        <input type="checkbox" checked={!!value} onChange={onChange} />
      </>
    )}
  </Grid>
);

export default EditableAttribute;
