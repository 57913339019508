import numberFormat from './numberFormat';

const priceFormat = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});

export const UFFormat = (value: number) => {
  return `${numberFormat(value)} UF`;
};

export default priceFormat;
