import React, { useState } from 'react';

import { Box, Grid, TextField } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import CloseableAlert from 'components/UI/CloseableAlert';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useUpdatePasswordMutation } from 'services/api/user.api';

const validatePassword = (password: string) => {
  // password must have at least 8 characters long
  const re = /^.{8,}$/;
  return re.test(password);
};

const Profile = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [updatePassword, { isError, isLoading, isSuccess }] = useUpdatePasswordMutation();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value));
    }
  };

  const verifyPasswordForm = () => {
    const values = !!currentPassword && !!newPassword && !!passwordConfirm;
    const errors = passwordError || passwordConfirmError;
    return values && !errors;
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(newPassword !== event.target.value);
    }
  };

  const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword === passwordConfirm && verifyPasswordForm()) {
      updatePassword({
        currentPassword,
        newPassword,
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  };

  return (
    <Box sx={{ p: { xs: 2 } }}>
      <Box component="form" onSubmit={handlePasswordSubmit}>
        <LoadingOverlay visible={isLoading} />
        {isError && <CloseableAlert msg="Error actualizando la contraseña" severity="error" />}
        {isSuccess && <CloseableAlert msg="Contraseña actualizada correctamente" severity="success" />}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                autoComplete="new-password"
                type="password"
                value={newPassword}
                error={passwordError}
                onInput={passwordVerify}
                placeholder="Contraseña nueva"
                label="Contraseña nueva"
                helperText={passwordError && 'La contraseña debe tener al menos 8 caracteres'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                value={passwordConfirm}
                error={passwordConfirmError}
                onInput={passwordConfirmVerify}
                placeholder="Confirmar contraseña"
                label="Confirmar contraseña"
                helperText={passwordConfirmError && 'Las contraseñas no coinciden'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="currentPassword"
                name="currentPassword"
                autoComplete="current-password"
                type="password"
                value={currentPassword}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value)}
                placeholder="Contraseña actual"
                label="Contraseña actual"
              />
            </Grid>
          </Grid>
          <PrimaryBtn
            type="submit"
            fullWidth
            variant="contained"
            disabled={!verifyPasswordForm()}
            sx={{ maxWidth: '200px', margin: 'auto' }}
          >
            Cambiar contraseña
          </PrimaryBtn>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
