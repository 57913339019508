import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';

import { DeleteIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import config from 'config';
import { useDeleteEvalMutation, useGetAllEvalsQuery } from 'services/api/evals.api';

import EvalModal from './EvalModal';

const Evals = () => {
  const { data: evals, refetch } = useGetAllEvalsQuery(null);

  const [deleteEval, { isSuccess: isDeleteSuccess }] = useDeleteEvalMutation();
  const [openEvalModal, setOpenEvalModal] = useState(false);
  const [evalId, setEvalId] = useState<number | null>(null);
  const [messageText, setMessageText] = useState('');
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [evalIdToDelete, setEvalIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (!openEvalModal || isDeleteSuccess) {
      // eslint-disable-next-line no-console
      refetch().catch((e) => console.error(e));
    }
  }, [openEvalModal, isDeleteSuccess]);

  const columns: GridColDef[] = [
    { field: 'score', headerName: 'Puntaje', width: 60, align: 'center' },
    {
      field: 'message.text',
      headerName: 'Mensaje',
      width: 500,
      valueGetter: (_value: unknown, row: { message: { text: string } }) => row.message.text,
    },
    {
      field: 'desiredResponse',
      headerName: 'Respuesta deseada',
      width: 500,
    },
    { field: 'comment', headerName: 'Comentario', width: 300 },
    {
      field: 'businessUnit.name',
      headerName: 'Empresa',
      width: 150,
      valueGetter: (_value: unknown, row: { businessUnit: { name: string } }) => row.businessUnit.name,
    },
    {
      field: 'message.clientId',
      headerName: 'Link',
      width: 130,
      valueGetter: (_value: unknown, row: { message: { clientId: string } }) => row.message.clientId,
      renderCell: (params) => (
        <a href={`${config.hostUrl}/clients/${params.value}`} target="_blank" rel="noreferrer">
          Ver respuesta
        </a>
      ),
    },
    {
      field: 'message.id',
      headerName: 'Link',
      width: 130,
      valueGetter: (_value: unknown, row: { message: { id: number } }) => row.message.id,
      renderCell: (params) => (
        <a href={`${config.hostUrl}/playground/${params.value}`} target="_blank" rel="noreferrer">
          Ver en playground
        </a>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      width: 200,
      valueFormatter: (value: string) => new Date(value).toLocaleString('es-CL'),
    },
    {
      field: 'id',
      headerName: 'Eliminar',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <PrimaryBtn
          onClick={() => {
            setEvalIdToDelete(params.value || null);
            setOpenDeleteAlert(true);
          }}
        >
          <DeleteIcon />
        </PrimaryBtn>
      ),
    },
  ];

  return (
    <Box>
      <AlertDialog
        openDialog={openDeleteAlert}
        setOpenDialog={setOpenDeleteAlert}
        header="¿Estas seguro que deseas eliminar esta evaluación?"
        msg="La evaluación será eliminada de manera permanente."
        confirmMsg="Si, eliminar"
        confirmAction={() => {
          if (evalIdToDelete) {
            deleteEval({ evalId: evalIdToDelete }).catch((e) => console.error(e)); // eslint-disable-line no-console
          }
          setOpenDeleteAlert(false);
          setOpenEvalModal(false);
        }}
      />
      <Box display="flex" justifyContent="flex-start" m={2} alignItems="center">
        <Typography variant="h4">Lista de Evaluaciones</Typography>
      </Box>
      <Box sx={{ height: 'calc(100vh - 100px)' }} p={1}>
        {evals && (
          <DataGrid
            rows={evals}
            columns={columns}
            onRowClick={(params: GridRowParams<{ id: number; message: { text: string } }>) => {
              setEvalId(params.row.id);
              setMessageText(params.row.message.text);
              setOpenEvalModal(true);
            }}
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
            }}
          />
        )}
      </Box>
      {openEvalModal && evalId && !openDeleteAlert && (
        <EvalModal setOpen={setOpenEvalModal} evalId={evalId} messageText={messageText} />
      )}
    </Box>
  );
};

export default Evals;
