import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

import type { SearchMessagesToolArguments } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';
import WhereFiltersComponent from './WhereFiltersComponent';

interface Props {
  args: string;
}

const SearchMessagesToolComponent: FC<Props> = ({ args }: Props) => {
  const argumentsObject = JSON.parse(args) as SearchMessagesToolArguments;
  return (
    <MessageWrapper caption="Búsqueda de mensajes" align="left">
      <Typography>Texto(s) a buscar: {argumentsObject.texts.map((text) => `'${text}'`).join(', ')}</Typography>
      <WhereFiltersComponent whereFilters={argumentsObject.where || []} />
      {argumentsObject.sender && argumentsObject.sender !== 'cualquiera' && (
        <Typography>Enviado por: {argumentsObject.sender}</Typography>
      )}
      {argumentsObject.limit && <Typography>Límite de resultados: {argumentsObject.limit}</Typography>}
    </MessageWrapper>
  );
};

export default SearchMessagesToolComponent;
