import React, { FC } from 'react';

import { Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { SearchIcon } from 'assets/icons';
import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';
import { useGetCarsQuery } from 'services/api/cars.api';
import priceFormat from 'services/format/priceFormat';

const SearchElementComponent: FC<{ searchTerm: string; setSearchTerm: (searchTerm: string) => void }> = ({
  searchTerm,
  setSearchTerm,
}) => (
  <TextField
    type="text"
    placeholder="Buscar"
    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
    value={searchTerm}
    sx={{
      '& .MuiInputBase-input': {
        fontSize: '14px',
        color: 'text.secondary',
        fontWeight: 'bold',
      },
      '& .MuiInputBase-root': {
        border: '1px solid #E5E5E5',
        borderRadius: '10px',
        padding: '0 10px',
      },
      backgroundColor: 'primary.contrastText',
      mx: 2,
    }}
    InputProps={{
      endAdornment: <SearchIcon />,
    }}
  />
);

const Cars = () => {
  const [businessUnitId, setBusinessUnitId] = React.useState<number>(-1);
  const [isUsed, setIsUsed] = React.useState<string>('null');
  const { data: cars } = useGetCarsQuery({ businessUnitId, isUsed });
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const columns: GridColDef[] = [
    { field: 'id', width: 70 },
    { field: 'businessUnitId' },
    { field: 'referenceId' },
    { field: 'brand', width: 130 },
    { field: 'model', width: 130 },
    { field: 'version', width: 250 },
    { field: 'year', width: 70 },
    { field: 'km', width: 80 },
    {
      field: 'price',
      width: 120,
      valueFormatter: (value) => priceFormat.format(value as number),
    },
    {
      field: 'monthlyFee',
      width: 100,
      valueFormatter: (value) => priceFormat.format(value as number),
    },
    { field: 'transmission', width: 120 },
    { field: 'carType', width: 120 },
    { field: 'category', width: 120 },
    { field: 'fuelType', width: 130 },
    { field: 'isUsed', width: 70 },
    { field: 'branchOfficeDirection', width: 250 },
    { field: 'color', width: 150 },
    { field: 'createdAt', width: 200 },
    { field: 'updatedAt', width: 200 },
    {
      field: 'link',
      width: 100,
      renderCell: (params) => (
        <a href={params.value as string} target="_blank" rel="noreferrer">
          Ver auto
        </a>
      ),
    },
    {
      field: 'image',
      width: 100,
      renderCell: (params) => (
        <a href={params.value as string} target="_blank" rel="noreferrer">
          Ver foto
        </a>
      ),
    },
    { field: 'equipment', width: 500, renderCell: (params) => JSON.stringify(params.value) },
  ];

  // Add a input to filter by car brand or model, use mui components

  let filteredCars = cars;

  if (searchTerm.length > 0) {
    const searchTermSet = searchTerm.split(' ');
    filteredCars = cars
      ?.map((car) => ({
        car,
        score: searchTermSet.filter((word) => `${car.brand} ${car.model} ${car.version}`.toLowerCase().includes(word))
          .length,
      }))
      .filter(({ score }) => score > 0)
      .sort((a, b) => b.score - a.score)
      .map(({ car }) => car);
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <Typography variant="h4">Lista de Autos</Typography>
        <SearchElementComponent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Box>
          <Select value={isUsed} onChange={(e) => setIsUsed(e.target.value)}>
            <MenuItem value="null">Usados y Nuevos</MenuItem>
            <MenuItem value="true">Usados</MenuItem>
            <MenuItem value="false">Nuevos</MenuItem>
          </Select>
          <BusinessUnitSelector
            businessUnitId={businessUnitId}
            setBusinessUnitId={(value: number) => {
              setBusinessUnitId(value);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100vh - 100px)', color: 'black' }}>
        {cars && <DataGrid rows={filteredCars} columns={columns} />}
      </Box>
    </Box>
  );
};

export default Cars;
