import React, { ReactNode } from 'react';

const textToReactNodes = (text: string): ReactNode[] => {
  const urlRegex = /(https?:\/\/[^\s",\\)]+|\*+\S.*?\S\*+|`.+?`)/g;
  const nodes: ReactNode[] = text.split(urlRegex).map((textSegment, index) => {
    if (textSegment.startsWith('http')) {
      return (
        <a
          href={textSegment}
          key={`${textSegment}-${index + 1}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          {textSegment}
        </a>
      );
    }
    if (textSegment.startsWith('`') && textSegment.endsWith('`')) {
      // use mono font for code
      return <code key={`${textSegment}-${index + 1}`}>{textSegment.slice(1, -1)}</code>;
    }
    if (textSegment.startsWith('*') && textSegment.endsWith('*')) {
      return <strong key={`${textSegment}-${index + 1}`}>{textSegment.slice(1, -1)}</strong>;
    }
    return textSegment;
  });
  return nodes;
};

export default textToReactNodes;
