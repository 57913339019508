import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Included: FC = () => {
  return (
    <Grid
      container
      justifyContent="center"
      py={{ xs: 4, lg: 8 }}
      sx={{ backgroundColor: 'white', borderRadius: '0 200px 0 0' }}
    >
      <Box textAlign="center" alignContent="center" width="100%">
        <Typography fontFamily="Roboto" fontSize={{ lg: '50px', xs: '35px' }} lineHeight="50px" fontWeight="600">
          ¿Qué incluimos?
        </Typography>
        <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center">
          <img alt="" src="/landing/included.svg" style={{ width: 'auto', marginTop: '-50px' }} />
        </Box>
        <Box display={{ xs: 'flex', md: 'none' }} justifyContent="left" pr={3}>
          <img alt="" src="/landing/included-mobile.svg" style={{ width: '100%' }} />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        justifyContent="center"
        sx={{
          backgroundColor: '#224267',
          borderRadius: '67px',
          width: '90%',
          marginTop: { xs: 8, sm: '-8px' },
        }}
      >
        <Box alignSelf="center" px={4} pt={{ xs: 4, lg: 0 }} textAlign={{ xs: 'center', lg: 'left' }}>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="medium"
            color="white"
          >
            Todo mediante
          </Typography>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="600"
            color="#F9D00A"
          >
            una conversacion natural
          </Typography>
        </Box>
        <Box pl={{ xs: 0, lg: 20 }}>
          <img alt="" src="/landing/chat-with-messages.svg" style={{ width: '100%' }} />
        </Box>
      </Box>
    </Grid>
  );
};

export default Included;
