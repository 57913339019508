import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Header from 'pages/LandingPage/components/Header';
import Navigation from 'pages/LandingPage/components/Navigation';
import { useCreateProspectMutation } from 'services/api/prospects.api';

const WhatsAppIntegrationSuccess = () => {
  // https://www.zeller.ai/WhatsAppIntegrationSuccess?client=xxxxCL&channels=[yyyyCH]&revoked=[zzzzCH]&state=someStatePassedThrough
  const [createProspect] = useCreateProspectMutation();
  // Extract query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const client = urlParams.get('client');
  const channels = urlParams.get('channels');
  const revoked = urlParams.get('revoked');
  const state = urlParams.get('state');

  useEffect(() => {
    createProspect({
      email: `${client || ''}_360Dialog@zeller.ai`,
      phone: '',
      rut: '',
      reason: `360 Integration with Zeller: client=${client}, channels=${channels}, revoked=${revoked}, state=${state}`,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error creating prospect', error);
    });
  }, []);
  return (
    <Box sx={{ backgroundColor: '#F1F6FC' }}>
      <Navigation />
      <Header />

      <Box
        sx={{
          position: 'absolute',
          top: '25%',
          left: '30%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid white',
          borderRadius: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <Grid container spacing={2} justifyContent="center" color="white" m={2} mb={4}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'medium', fontSize: 50 }}>¡Integración Exitosa!</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'medium', fontSize: 20 }}>
                Nos contactaremos contigo para los próximos pasos.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhatsAppIntegrationSuccess;
