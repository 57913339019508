import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import { format } from 'rut.js';
import { useCreateDemochatClientMutation } from 'services/api/demochat';
import getReCaptchaToken from 'utils/getReCaptchaToken';

import ChatWrapper from './ChatWrapper';

const PompeyoBrands = ['OPEL', 'KIA', 'NISSAN', 'PEUGEOT', 'MG', 'GEELY', 'DFSK', 'SUBARU', 'CITROËN'];
const SalfaBrands = ['JEEP', 'CHERY', 'RAM', 'MITSUBISHI', 'SSANGYONG', 'FIAT', 'JMC', 'GAC', 'EXEED', 'BYD'];
const OtherBusinessUnitsBrands = ['OPEL', 'KIA', 'NISSAN', 'PEUGEOT', 'MG', 'GEELY', 'DFSK', 'SUBARU', 'CITROËN'];

const mapAttributes = (key: string) => {
  if (key === 'rut') {
    return 'Rut';
  }
  if (key === 'email') {
    return 'Email';
  }
  if (key === 'modelOfInterest') {
    return 'Modelo de Interés';
  }
  if (key === 'firstName') {
    return 'Nombre';
  }
  if (key === 'lastName') {
    return 'Apellido';
  }
  return key;
};

const NewDemochat: React.FC = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();

  const [openForm, setOpenForm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [clientData, setClientData] = useState({
    firstName: '',
    lastName: '',
    rut: '',
    email: '',
    modelOfInterest: '',
    brandOfInterest: companyIdentifier === 'salfa-rent' ? 'USADOS' : '',
  });

  const localStorageKey = `zeller-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);

  const [createDemochatClient, { data: createClientData, isError }] = useCreateDemochatClientMutation();
  const navigate = useNavigate();

  if (!companyIdentifier) {
    navigate('/demochat'); // Error page
    return null;
  }

  let brands: string[] = [];
  if (companyIdentifier === 'salfa-nurturing') {
    brands = SalfaBrands;
  } else if (companyIdentifier === 'pompeyo-chat') {
    brands = PompeyoBrands;
  } else {
    brands = OtherBusinessUnitsBrands;
  }

  const startChat = () => {
    getReCaptchaToken()
      .then((recaptchaToken) => {
        createDemochatClient({ companyIdentifier, recaptchaToken, clientData }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error creating demo chat client', error);
          setOpenError(true);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting recaptcha token', error);
        setOpenError(true);
      });
  };

  const navigateToChat = () => {
    navigate(`/demochat/${companyIdentifier}`);
  };

  useEffect(() => {
    if (createClientData) {
      localStorage.setItem(localStorageKey, createClientData.id);
      navigateToChat();
    }
  }, [createClientData]);

  useEffect(() => {
    if (isError) {
      setOpenError(true);
    }
  }, [isError]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier}>
      <>
        <AlertDialog
          openDialog={openError}
          setOpenDialog={setOpenError}
          header="Ha ocurrido un error iniciando el chat"
          msg="Intenta nuevamente, con otros datos"
        />
        {openForm ? (
          <Stack spacing={2} pt={2} style={{ backgroundColor: 'white' }}>
            {Object.entries(clientData)
              .filter(([k]) => !k.includes('brand'))
              .map(([key, value]) => (
                <TextField
                  key={key}
                  label={mapAttributes(key)}
                  value={value}
                  onChange={(e) =>
                    setClientData({ ...clientData, [key]: key === 'rut' ? format(e.target.value) : e.target.value })
                  }
                  fullWidth
                />
              ))}
            {companyIdentifier !== 'salfa-rent' && (
              <FormControl>
                <InputLabel>Marca de interés</InputLabel>
                <Select
                  value={clientData.brandOfInterest}
                  label="Marca"
                  onChange={(e) => setClientData({ ...clientData, brandOfInterest: e.target.value })}
                >
                  {brands.map((brand) => (
                    <MenuItem key={brand} value={brand}>
                      {brand}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button variant="contained" color="primary" onClick={startChat}>
              Iniciar Chat
            </Button>
          </Stack>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Button variant="contained" color="primary" onClick={startChat}>
              Iniciar Chat
            </Button>
            {['salfa-nurturing', 'pompeyo-chat', 'auto-chat', 'salfa-rent'].includes(companyIdentifier) && (
              <Button variant="contained" color="primary" onClick={() => setOpenForm(true)}>
                Iniciar Chat con Datos
              </Button>
            )}

            {clientPhoneIdentifier && (
              <Button variant="contained" color="primary" onClick={navigateToChat}>
                Continuar chat
              </Button>
            )}
          </Box>
        )}
      </>
    </ChatWrapper>
  );
};

export default NewDemochat;
