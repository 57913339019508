const SITE_KEY = import.meta.env.VITE_RECAPTCHA_CLIENT_KEY || '';
const NODE_ENV = import.meta.env.NODE_ENV || 'development';

interface WindowWithGrecaptcha extends Window {
  grecaptcha: {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
  };
}

const getReCaptchaToken = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (NODE_ENV === 'test') {
      resolve('test');
      return;
    }
    const windowWithGrecaptcha = window as unknown as WindowWithGrecaptcha;
    windowWithGrecaptcha.grecaptcha.ready(() => {
      windowWithGrecaptcha.grecaptcha
        .execute(SITE_KEY, { action: 'submit' })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export default getReCaptchaToken;
