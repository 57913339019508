import React from 'react';

import { Box, Typography } from '@mui/material';

import AccessDeniedImg from 'assets/images/access_denied.webp';
import Conversation from 'components/common/Conversation';
import useQuery from 'hooks/useQuery';
import { useGetPublicClientMessagesQuery } from 'services/api/public-access';

const PublicChat = () => {
  const query = useQuery();
  const clientId = query.get('clientId');
  const token = query.get('token');
  if (!clientId || !token)
    return (
      <div>
        <p>Client ID or token not found</p>
      </div>
    );
  const { data: messages, error } = useGetPublicClientMessagesQuery({
    clientId: Number(clientId),
    token: String(token),
  });
  if (error) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={AccessDeniedImg} alt="error" width="500" />
        <Typography variant="h4">
          El link al que estás tratando de acceder no es válido. Puede que haya vencido.
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <Box
        sx={{
          backgroundImage: 'url("/whatsapp-background.png")',
          backgroundColor: '#efeae2',
          backgroundRepeat: 'repeat',
          backgroundSize: '412.5px 749.25px',
          width: { xs: '100%', md: '80%' },
        }}
      >
        <Conversation conversation={messages || []} isFilteredConversation fullHeight />
      </Box>
    </Box>
  );
};

export default PublicChat;
