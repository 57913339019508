import jwtDecode from 'jwt-decode';

import { RootState } from './redux/store';

const deleteUser = () => {
  const persist = localStorage.getItem('persist:root');
  if (persist) {
    const parsedPersist = JSON.parse(persist) as RootState;
    localStorage.setItem('persist:root', JSON.stringify({ ...parsedPersist, user: null }));
  }
};

// eslint-disable-next-line import/prefer-default-export
export const checkIfTokenIsValid = (user: RootState['user'] | null) => {
  try {
    if (user) {
      // TODO: delete this condition once all users have logged in at least once after the new version of the app was released (1 week after this commit)
      // businessUnitsIds is a required field for all non admin users in the new version of the app
      if (!user.isAdmin && !user.businessUnitIds?.length) {
        deleteUser();
        return false;
      }
      const decodedJwt: { exp: number } = jwtDecode(user.accessToken);
      if (decodedJwt.exp >= Date.now() / 1000) {
        return true;
      }
      deleteUser();
    }
    return false;
  } catch (error) {
    return false;
  }
};
