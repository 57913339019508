import { ProspectDraft, Prospect } from '../types/prospects';
import baseApi from './baseApi';

const prospectsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createProspect: build.mutation({
      query: (body: ProspectDraft) => ({
        url: '/prospects',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Prospect): Prospect => response,
    }),
  }),
  overrideExisting: false,
});

export const { useCreateProspectMutation } = prospectsApi;
