import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIos } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  companyIdentifier: string | undefined;
  includeGoToNewChatButton?: boolean;
  children: JSX.Element;
}

const ChatWrapper: React.FC<Props> = ({ companyIdentifier, children, includeGoToNewChatButton }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: { xs: '100%' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', md: '60%' },
          flex: 1,
          pt: 1,
          color: 'black',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
          {includeGoToNewChatButton && (
            <Box sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', width: 50, display: 'flex' }}>
              <IconButton onClick={() => navigate(`/widgetChat/${companyIdentifier}/newChat`)}>
                <ArrowBackIos sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
          <Box sx={{ alignItems: 'center' }} ml={2}>
            <Typography variant="h6" textAlign="center">
              Claudio
            </Typography>
            <Typography variant="body1" textAlign="center">
              En línea
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
        height="100%"
        maxHeight="calc(100% - 200px)"
        overflow="hidden"
      >
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

// Add the default props
ChatWrapper.defaultProps = {
  includeGoToNewChatButton: false,
};

export default ChatWrapper;
