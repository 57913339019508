import { styled, TextField } from '@mui/material';

const RoundedInput = styled(TextField)(({ theme }) => ({
  '& input': { paddingLeft: theme.spacing(2), color: 'black' },
  '& fieldset': {
    borderRadius: '25px',
    borderColor: theme.palette.primary.main,
  },
}));

export default RoundedInput;
