import React, { FC, useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useEditClientQuestionMutation, useGetOneClientQuestionsQuery } from 'services/api/clientQuestions.api';

interface Props {
  setOpen: (open: boolean) => void;
  clientQuestionId: number;
}

const ClientQuestionModal: FC<Props> = ({ setOpen, clientQuestionId }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const [editClientQuestion, { data: clientQuestionEditResponse }] = useEditClientQuestionMutation();

  const { data: clientQuestionToEdit } = useGetOneClientQuestionsQuery({ clientQuestionId });

  useEffect(() => {
    if (clientQuestionToEdit) {
      setQuestion(clientQuestionToEdit.question);
      setAnswer(clientQuestionToEdit.answer || '');
    }
  }, [clientQuestionToEdit]);

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '95%',
        }}
      >
        <Stack spacing={2} p={2}>
          {clientQuestionEditResponse ? (
            <>
              <Typography variant="h5">Se ha editado la pregunta con éxito!</Typography>
              <PrimaryBtn onClick={() => setOpen(false)}>Cerrar</PrimaryBtn>
            </>
          ) : (
            <>
              <TextField
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                label="Pregunta"
                placeholder={question}
                multiline
                maxRows={10}
              />
              <TextField
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                label="Respuesta"
                multiline
                maxRows={10}
              />
              <PrimaryBtn
                disabled={question === null}
                onClick={() => {
                  if (question !== null) {
                    if (clientQuestionToEdit && clientQuestionId) {
                      editClientQuestion({
                        clientQuestionId,
                        question,
                        answer,
                        // eslint-disable-next-line no-console
                      }).catch((e) => console.error(e));
                    }
                  }
                }}
              >
                Editar
              </PrimaryBtn>
            </>
          )}
        </Stack>
      </Paper>
    </Modal>
  );
};

export default ClientQuestionModal;
