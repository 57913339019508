import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';

import type { AskAssistantsResponse } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';

interface Props {
  assistantData: AskAssistantsResponse;
}

const AskAssistantsResponseComponent: FC<Props> = ({ assistantData }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <MessageWrapper caption="Respuesta" align="left">
      {Object.entries(assistantData.aggregatedResponses).map(([key, values]) => (
        <Box key={key} sx={{ maxWidth: '100%' }}>
          <Typography variant="body1" style={{ overflowWrap: 'break-word' }}>
            {key}({values.length}):
            {values.slice(0, expanded ? values.length : 20).map((value) => (
              <Link key={value} target="_blank" rel="noreferrer" to={`/clients/${value}`} style={{ marginLeft: 8 }}>
                {value}
              </Link>
            ))}
          </Typography>
        </Box>
      ))}
      {Object.values(assistantData.aggregatedResponses).some((values) => values.length > 20) && (
        <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Ver menos' : 'Ver más'}</Button>
      )}
      {assistantData.status && <Typography variant="body1">{assistantData.status}</Typography>}
    </MessageWrapper>
  );
};

export default AskAssistantsResponseComponent;
