import React from 'react';

import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import useIsMobile from 'hooks/useIsMobile';

interface SectionHeaderInterface {
  text: string;
  button: React.ReactNode | null;
}

interface ElevationScrollInterface {
  children: React.ReactElement;
}

const ElevationScroll: React.FC<ElevationScrollInterface> = ({ children }) => {
  const { isMobile } = useIsMobile();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger && isMobile ? 4 : 0,
  });
};

const SectionHeader: React.FC<SectionHeaderInterface> = ({ text, button }) => (
  <ElevationScroll>
    <AppBar
      sx={{
        backgroundColor: '#F9FAFD',
        zIndex: '1',
        position: { lg: 'static' },
      }}
    >
      <Toolbar
        sx={{
          pt: { xs: '18px', xl: 5 },
          pb: { xs: '20px', xl: 5 },
          pl: { xs: '65px', lg: '0 !important' },
          pr: { xl: '0 !important' },
          minHeight: { xs: '80px', xl: '138px' },
          display: 'flex',
          justifyContent: { xs: 'start', lg: 'space-between' },
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', lg: 'auto' },
            justifyContent: 'space-between',
            ml: 2,
          }}
        >
          {text !== '' && (
            <Typography variant="h4" sx={{ mr: { xs: 2, lg: 6 }, ml: 1 }} color="#000">
              {text}
            </Typography>
          )}
          {button}
        </Box>
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, justifyContent: 'flex-end' }}>
          <Typography variant="h6" color="#000">
            {new Date().toLocaleDateString('es-CL')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  </ElevationScroll>
);

export default SectionHeader;
