import type { FollowUpConfig } from 'services/types/followUpConfig';

import baseApi from './baseApi';

const followUpConfigApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFollowUps: build.query({
      query: (businessUnitId: number) => ({
        url: `/admin/followUpConfig/businessUnit/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response: FollowUpConfig[]) => response,
    }),
    editFollowUp: build.mutation({
      query: (followUpConfig: FollowUpConfig) => ({
        url: `admin/followUpConfig/${followUpConfig.id}`,
        method: 'PATCH',
        body: followUpConfig,
      }),
      transformResponse: (response: FollowUpConfig) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFollowUpsQuery, useEditFollowUpMutation } = followUpConfigApi;
