import React from 'react';

import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { CloseIcon } from 'assets/icons';

interface CloseableAlertInterface {
  msg: string;
  severity: 'warning' | 'success' | 'error' | 'info';
  title?: string | null;
}

const CloseableAlert: React.FC<CloseableAlertInterface> = ({ msg, severity, title }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 1 }}
        severity={severity}
      >
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {msg}
      </Alert>
    </Collapse>
  );
};

CloseableAlert.defaultProps = {
  title: null,
};

export default CloseableAlert;
