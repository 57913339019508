import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useCreateDemochatClientMutation } from 'services/api/demochat';
import getReCaptchaToken from 'utils/getReCaptchaToken';

import ChatWrapper from './ChatWrapper';

const NewWidgetChat: React.FC = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `zeller-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const [createDemochatClient, { data: createClientData }] = useCreateDemochatClientMutation();
  const navigate = useNavigate();
  if (!companyIdentifier) {
    navigate('/widgetChat'); // Error page
    return null;
  }

  const startChat = () => {
    getReCaptchaToken()
      .then((recaptchaToken) => {
        createDemochatClient({ companyIdentifier, recaptchaToken, isFromWidget: true }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error creating demo chat client', error);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting recaptcha token', error);
      });
  };

  const navigateToChat = () => {
    navigate(`/widgetChat/${companyIdentifier}`);
  };

  useEffect(() => {
    if (createClientData) {
      localStorage.setItem(localStorageKey, createClientData.id);
      navigateToChat();
    }
  }, [createClientData]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <Typography variant="h6">
          Bienvenido al chat de {companyIdentifier.replaceAll('-', ' ').replace(/^\w/, (c) => c.toUpperCase())}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {clientPhoneIdentifier
            ? 'Ya has iniciado un chat con nosotros. Puedes elegir si continuar con ese, o iniciar uno nuevo.'
            : 'Inicia un chat con nosotros.'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            width: '80%',
            alignItems: 'center',
          }}
        >
          <PrimaryBtn variant="contained" onClick={startChat} fullWidth sx={{ my: 1 }}>
            Iniciar Chat
          </PrimaryBtn>
          {clientPhoneIdentifier && (
            <PrimaryBtn variant="contained" color="primary" onClick={navigateToChat} fullWidth sx={{ my: 1 }}>
              Continuar chat
            </PrimaryBtn>
          )}
        </Box>
      </Box>
    </ChatWrapper>
  );
};

export default NewWidgetChat;
