import React, { FC, ReactNode } from 'react';

import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

interface ChartCardProps {
  children: ReactNode;
  title: string;
  description?: string;
}

const ChartCard: FC<ChartCardProps> = ({ title, children, description }: ChartCardProps) => {
  return (
    <Card sx={{ m: 1, boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)', borderRadius: '16px' }}>
      <CardContent>
        <Typography component="h2" variant="h6" color="primary">
          {title}
        </Typography>
        {description && (
          <Typography color="grey" variant="body2">
            {description}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

ChartCard.defaultProps = {
  description: '',
};

export default ChartCard;
