// example -> "+,=,0x09,0x0a,0x0d,0x20" -> ""
// remove '-' from start of string

const removeCSVInjection = (str: string): string => {
  // remove special characters ("+","=","-",",","0x09","0x0a","0x0d","0x20")
  const specialCharacters = ['\\+', '\\=', '\\,', '0x09', '0x0a', '0x0d', '0x20'];
  return str.replace(new RegExp(specialCharacters.join('|'), 'g'), '').replace(/^-*/, '');
};

export default removeCSVInjection;
