import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { BackIcon } from 'assets/icons';
import { PrimaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <>
      <SectionHeader text="Error 401" button={null} />
      <PaperContainer>
        <Typography variant="h4" mb={2}>
          Oops... Parece que no tienes permisos para acceder a esta página
        </Typography>
        <img
          src="https://64.media.tumblr.com/e27cb0108080a13a7618a34d4492aae8/tumblr_om3wzrm1ou1rn5gv3o1_500.gif"
          alt="success gif"
          width="300px"
        />
        <PrimaryBtn
          onClick={() => navigate('/')}
          sx={{ mt: 6, width: '200px' }}
          startIcon={<BackIcon />}
          variant="contained"
        >
          Volver al Inicio
        </PrimaryBtn>
      </PaperContainer>
    </>
  );
};

export default Unauthorized;
