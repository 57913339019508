import baseApi from './baseApi';

export type DbOutput = Record<string, string | number | object>;
const dbRequestsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    adminGenerateAIQuery: build.mutation({
      query: (body: { userQuery: string }) => ({
        url: '/admin/db/ai_generate_query',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { description: string; sqlQuery: string }) => response,
    }),
    adminExecuteQuery: build.mutation({
      query: (body: { userQuery: string }) => ({
        url: '/admin/db/execute',
        method: 'POST',
        body,
      }),
      transformResponse: (response: DbOutput[]): DbOutput[] => response,
    }),
  }),
  overrideExisting: false,
});

export const { useAdminGenerateAIQueryMutation, useAdminExecuteQueryMutation } = dbRequestsApi;
