import React from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorImg from 'assets/images/404.webp';
import { PrimaryBtn } from 'components/UI/Buttons';

// If this page is loaded I think there are no palette or theme styles available
const ErrorLoadFailed = () => (
  <Paper
    sx={{
      width: { xs: '90%', md: '80%', lg: '60%' },
      borderRadius: '20px',
      py: 3,
      px: { xs: 2, lg: 4 },
      my: 2,
      display: 'grid',
      justifyItems: 'center',
      ml: { xs: 0, md: '40px' },
      textAlign: 'center',
    }}
  >
    <Typography variant="h3" mb={2} fontSize="30px" fontWeight="bold">
      Tuvimos un problema cargando la página
    </Typography>

    <PrimaryBtn
      onClick={() => window.location.reload()}
      sx={{ width: '200px' }}
      variant="contained"
      startIcon={<ReplayIcon />}
    >
      Recargar
    </PrimaryBtn>
    <Typography variant="body1" my={2} fontSize="18px" fontWeight="bold">
      Si el problema persiste, por favor escríbenos a nuestro chat.
    </Typography>
    <img src={ErrorImg} alt="Error" style={{ width: 'min(450px, 90%)' }} />
  </Paper>
);

export default ErrorLoadFailed;
