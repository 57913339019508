import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';

import { DeleteIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import config from 'config';
import { useDeleteClientQuestionMutation, useGetAllClientQuestionsQuery } from 'services/api/clientQuestions.api';

import ClientQuestionModal from './ClientQuestionModal';

const ClientQuestions = () => {
  const { data: clientQuestions, refetch } = useGetAllClientQuestionsQuery(null);

  const [deleteClientQuestion, { isSuccess: isDeleteSuccess }] = useDeleteClientQuestionMutation();
  const [openClientQuestionModal, setOpenClientQuestionModal] = useState(false);
  const [clientQuestionId, setClientQuestionId] = useState<number | null>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [clientQuestionIdToDelete, setClientQuestionIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (!openClientQuestionModal || isDeleteSuccess) {
      // eslint-disable-next-line no-console
      refetch().catch((e) => console.error(e));
    }
  }, [openClientQuestionModal, isDeleteSuccess]);

  const columns: GridColDef[] = [
    { field: 'question', headerName: 'Pregunta', width: 500 },
    {
      field: 'answer',
      headerName: 'Respuesta',
      width: 500,
    },
    {
      field: 'clientId',
      headerName: 'Link',
      width: 130,
      renderCell: (params) => (
        <a href={`${config.hostUrl}/clients/${params.value}`} target="_blank" rel="noreferrer">
          Ver conversación
        </a>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      width: 200,
      valueFormatter: (value: string) => new Date(value).toLocaleString('es-CL'),
    },
    {
      field: 'id',
      headerName: 'Eliminar',
      width: 100,
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <PrimaryBtn
          onClick={() => {
            setClientQuestionIdToDelete(params.value || null);
            setOpenDeleteAlert(true);
          }}
        >
          <DeleteIcon />
        </PrimaryBtn>
      ),
    },
  ];

  return (
    <Box>
      <AlertDialog
        openDialog={openDeleteAlert}
        setOpenDialog={setOpenDeleteAlert}
        header="¿Estas seguro que deseas eliminar esta pregunta?"
        msg="La pregunta será eliminada de manera permanente."
        confirmMsg="Si, eliminar"
        confirmAction={() => {
          if (clientQuestionIdToDelete) {
            deleteClientQuestion({ clientQuestionId: clientQuestionIdToDelete }).catch((e) => console.error(e)); // eslint-disable-line no-console
          }
          setOpenDeleteAlert(false);
          setOpenClientQuestionModal(false);
        }}
      />
      <Box display="flex" justifyContent="flex-start" m={2} alignItems="center">
        <Typography variant="h4">Lista de Preguntas</Typography>
      </Box>
      <Box sx={{ height: 'calc(100vh - 100px)' }} p={1}>
        {clientQuestions && (
          <DataGrid
            rows={clientQuestions}
            columns={columns}
            onRowClick={(params: GridRowParams<{ id: number }>) => {
              setClientQuestionId(params.row.id);
              setOpenClientQuestionModal(true);
            }}
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
            }}
          />
        )}
      </Box>
      {openClientQuestionModal && clientQuestionId && !openDeleteAlert && (
        <ClientQuestionModal setOpen={setOpenClientQuestionModal} clientQuestionId={clientQuestionId} />
      )}
    </Box>
  );
};

export default ClientQuestions;
