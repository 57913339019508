import { ClientQuestion } from 'services/types/clientQuestion';

import baseApi from './baseApi';

const clientQuestionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    editClientQuestion: build.mutation({
      query: (params: { clientQuestionId: number; question: string; answer?: string }) => ({
        url: `/admin/clientQuestions/${params.clientQuestionId}`,
        method: 'PATCH',
        body: params,
      }),
      transformResponse: (response: ClientQuestion): ClientQuestion => response,
    }),
    getAllClientQuestions: build.query({
      query: () => ({
        url: '/admin/clientQuestions/all',
        method: 'GET',
      }),
      transformResponse: (response: ClientQuestion[]): ClientQuestion[] => response,
    }),
    getOneClientQuestions: build.query({
      query: (params: { clientQuestionId: number }) => ({
        url: `/admin/clientQuestions/${params.clientQuestionId}`,
        method: 'GET',
      }),
      transformResponse: (response: ClientQuestion | null | undefined): ClientQuestion | null | undefined => response,
    }),
    deleteClientQuestion: build.mutation({
      query: (params: { clientQuestionId: number }) => ({
        url: `/admin/clientQuestions/delete/${params.clientQuestionId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: void): void => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllClientQuestionsQuery,
  useGetOneClientQuestionsQuery,
  useEditClientQuestionMutation,
  useDeleteClientQuestionMutation,
} = clientQuestionsApi;
