import {
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AccountCircle,
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Article,
  AttachMoney,
  Attractions,
  Cached,
  Calculate,
  Check,
  ChildCare,
  Close,
  Delete,
  DirectionsCar,
  Edit,
  Email,
  ExpandLess,
  ExpandMore,
  Help,
  Home,
  ListAlt,
  LockOutlined,
  Menu,
  MenuBook,
  Message,
  MoreVert,
  NorthEastRounded,
  Person,
  Savings,
  Search,
  SouthWestRounded,
  SportsScore,
  SupportAgent,
  ThumbDown,
  ThumbUp,
  TrendingDown,
  TrendingUp,
  WhatsApp,
} from '@mui/icons-material';

export const ProfileIcon = Person;
export const MenuIcon = Menu;
export const DebtsIcon = AccountBalanceWallet;
export const BudgetsIcon = Calculate;
export const CloseIcon = Close;
export const BlogIcon = MenuBook;
export const ChatIcon = Message;
export const SupportIcon = SupportAgent;
export const EmailIcon = Email;
export const WhatsAppIcon = WhatsApp;
export const BackIcon = ArrowBackIosNew;
export const ForwardIcon = ArrowForwardIos;
export const EditIcon = Edit;
export const InstitutionIcon = AccountBalance;
export const DeleteIcon = Delete;
export const AddIcon = Add;
export const ListIcon = ListAlt;
export const ClockIcon = AccessTime;
export const HomeIcon = Home;
export const ArticleIcon = Article;
export const EntertainmentIcon = Attractions;
export const ChildIcon = ChildCare;
export const GoalIcon = SportsScore;
export const IncomeIcon = TrendingUp;
export const ExpenseIcon = TrendingDown;
export const SavingsIcon = Savings;
export const ExpandMoreIcon = ExpandMore;
export const ExpandLessIcon = ExpandLess;
export const LikeIcon = ThumbUp;
export const HelpIcon = Help;
export const MoreIcon = MoreVert;
export const CheckIcon = Check;
export const ProfileCircleIcon = AccountCircle;
export const LockIcon = LockOutlined;
export const OutwardsIcon = NorthEastRounded;
export const InwardsIcon = SouthWestRounded;
export const RefreshIcon = Cached;
export const MoneyIcon = AttachMoney;
export const DislikeIcon = ThumbDown;
export const SearchIcon = Search;
export const CarIcon = DirectionsCar;
