import React, { useState } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import useCurrentUser from 'hooks/useCurrentUser';
import { useCreatePromptSectionMutation } from 'services/api/promptSections.api';
import { PromptSectionDraft } from 'services/types/promptSection';

import PromptSectionModal from './PromptSectionModal';

interface Props {
  businessUnitId: number;
  refetch?: () => void;
}

const NewPromptSectionButton = (props: Props) => {
  const { businessUnitId, refetch } = props;
  const [createPromptSection] = useCreatePromptSectionMutation();
  const [openModal, setOpenModal] = useState(false);
  const currentUser = useCurrentUser();

  const handleCreatePromptSection = (promptSection: PromptSectionDraft) => {
    createPromptSection({
      businessUnitId,
      promptSection,
    })
      .then(refetch)
      .catch(console.error); // eslint-disable-line no-console
  };

  if (!currentUser || !currentUser.isAdmin) {
    return null;
  }

  return (
    <Box mr={4}>
      <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
        Nuevo texto
      </Button>
      {openModal && (
        <PromptSectionModal
          close={() => setOpenModal(false)}
          title="Nueva información"
          save={handleCreatePromptSection}
          promptSection={{ name: '', displayName: '', value: '', permission: 'hidden', businessUnitId }}
        />
      )}
    </Box>
  );
};

NewPromptSectionButton.defaultProps = {
  refetch: () => {},
};

export default NewPromptSectionButton;
