import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import type { AskAssistantsToolArguments } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';
import WhereFiltersComponent from './WhereFiltersComponent';

interface Props {
  args: string;
}
const AskAssistantsToolComponent: FC<Props> = ({ args }: Props) => {
  const argumentsObject = JSON.parse(args) as AskAssistantsToolArguments;
  const informationToInclude = [...(argumentsObject.clientFields || [])];
  if (argumentsObject.includeMessages) {
    informationToInclude.push('Mensajes');
  }
  return (
    <MessageWrapper caption="Revisar cada conversación" align="left">
      <Box justifyContent="flex-start" display="flex" flexDirection="row">
        <Typography variant="body1">Responder esta pregunta {argumentsObject.clarifiedQuestion}</Typography>
      </Box>
      <Box>
        <Typography variant="body1">Información a incluir</Typography>
        {informationToInclude.map((field) => (
          <Typography key={field} variant="body1">
            - {field}
          </Typography>
        ))}
        <WhereFiltersComponent whereFilters={argumentsObject.where || []} />
      </Box>
    </MessageWrapper>
  );
};

export default AskAssistantsToolComponent;
