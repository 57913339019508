import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Send from '@mui/icons-material/Send';
import { Box, Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import Pusher from 'pusher-js';
import { useGetDemoClientMessagesQuery, useCreateDemochatMessageMutation } from 'services/api/demochat';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const WidgetChat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `zeller-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const navigate = useNavigate();
  const [messageToSend, setMessageToSend] = useState('');
  const [createDemochatMessage] = useCreateDemochatMessageMutation();

  const { data: messages, refetch } = useGetDemoClientMessagesQuery({
    clientPhoneIdentifier: clientPhoneIdentifier ?? '',
  });
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  if (!clientPhoneIdentifier) {
    // navigate to PusherSignin
    navigate(`/widgetChat/${companyIdentifier}/newChat`);
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${clientPhoneIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${clientPhoneIdentifier}`);
      pusher.disconnect();
    };
  }, [clientPhoneIdentifier, setRefetchFlag]);

  const lastMessageRole = messages?.at(-1)?.role;
  const isAssistantWriting = (lastMessageRole && lastMessageRole !== 'assistant') ?? false;

  const handleSendMessage = () => {
    if (!clientPhoneIdentifier || !messageToSend) {
      return;
    }
    createDemochatMessage({ clientPhoneIdentifier, message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} includeGoToNewChatButton>
      <>
        <Box
          style={{
            height: 'calc(100vh - 140px)',
            maxHeight: 'calc(100vh - 140px)',
            overflow: 'auto',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Conversation
            conversation={messages || []}
            isFilteredConversation
            isClientView
            isAssistantWriting={isAssistantWriting}
          />
        </Box>
        <Grid container width="100%" p={1} justifyContent="space-between" height="72.5px" bgcolor="#fff">
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={handleSendMessage}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
                color: 'primary.main',
              }}
            />
          </IconButton>
        </Grid>
      </>
    </ChatWrapper>
  );
};

export default WidgetChat;
