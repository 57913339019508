import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Dashboard from '@mui/icons-material/Dashboard';
import Logout from '@mui/icons-material/Logout';
import PollIcon from '@mui/icons-material/Poll';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { ProfileCircleIcon, CarIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import { signOutUser } from 'redux/slices/user.slice';

export const drawerWidth = 250;

const SideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '16px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 400,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 400,
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const CurrentSideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '16px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 600,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 600,
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.8',
    '& span': {
      color: '#fff',
    },
  },
}));

interface SideBarItemInterface {
  text: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  path:
    | '/'
    | '/users'
    | '/clients'
    | '/businessUnit'
    | '/analysis'
    | '/businessUnits'
    | '/dashboard'
    | '/db-reader'
    | '/cars'
    | '/evals'
    | '/information'
    | '/clientQuestions';
}

const SignOutButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(signOutUser());
    navigate('/');
  };

  return (
    <ListItem sx={{ px: '16px' }}>
      <SideBarItemButton onClick={handleClick} sx={{ px: 2 }}>
        <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </SideBarItemButton>
    </ListItem>
  );
};

const SideBarButton: React.FC<SideBarItemInterface> = ({ text, Icon, isSelected, path }) => {
  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <ListItem sx={{ px: '16px', py: '4px' }}>
        {isSelected ? (
          <CurrentSideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </CurrentSideBarItemButton>
        ) : (
          <SideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </SideBarItemButton>
        )}
      </ListItem>
    </Link>
  );
};

const SideBar = () => {
  const { path } = useCurrentRoute();
  const user = useCurrentUser();
  const isAdmin = !!user?.isAdmin;

  const isAdminRoute = path.includes('/admin');

  const navigate = useNavigate();

  const firstSection: SideBarItemInterface[] = [
    {
      text: 'Clientes',
      Icon: <ProfileCircleIcon />,
      path: '/clients',
      isSelected: path.includes('/clients'),
    },
    {
      text: 'Dashboard',
      Icon: <Dashboard />,
      isSelected: path.includes('/dashboard'),
      path: '/dashboard',
    },
    {
      text: 'Análisis (βeta)',
      Icon: <AutoGraphIcon />,
      path: '/analysis',
      isSelected: path.includes('/analysis'),
    },
    {
      text: 'Ajustes',
      Icon: <TuneIcon />,
      path: '/information',
      isSelected: path.includes('/information'),
    },
  ];

  const secondSection: SideBarItemInterface[] = [
    {
      text: 'Empresas',
      Icon: <Dashboard />,
      path: '/businessUnits',
      isSelected: path.includes('/businessUnits'),
    },
    {
      text: 'Usuarios',
      Icon: <ProfileCircleIcon />,
      path: '/users',
      isSelected: path.includes('/users'),
    },
    {
      text: 'Autos',
      Icon: <CarIcon />,
      path: '/cars',
      isSelected: path.includes('/cars'),
    },
    {
      text: 'DB reader',
      Icon: <ProfileCircleIcon />,
      path: '/db-reader',
      isSelected: path.includes('/db-reader'),
    },
    {
      text: 'Evals',
      Icon: <PollIcon />,
      path: '/evals',
      isSelected: path.includes('/evals'),
    },
    {
      text: 'Preguntas',
      Icon: <QuestionMarkIcon />,
      path: '/clientQuestions',
      isSelected: path.includes('/clientQuestions'),
    },
  ];

  return (
    <Box
      component="nav"
      sx={{ width: drawerWidth, zIndex: 1, boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)' }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            border: 0,
          },
        }}
        open
      >
        <Toolbar
          sx={{
            py: { xs: '26px', xl: 6 },
            px: '0px !important',
            height: { xs: 'min(100px, 14vh)', xl: '138px' },
            cursor: 'pointer',
          }}
          onClick={() => {
            if (isAdminRoute) {
              navigate('/admin');
            } else {
              navigate('/');
            }
          }}
        >
          <img src={logo} alt="logo" style={{ width: '130%', maxWidth: '240px' }} />
        </Toolbar>

        <List sx={{ pb: { xs: '10px', xl: '30px' } }}>
          {firstSection.map((section) => (
            <SideBarButton
              Icon={section.Icon}
              text={section.text}
              key={section.text}
              isSelected={section.isSelected}
              path={section.path}
            />
          ))}
        </List>
        {isAdmin && (
          <>
            <Divider sx={{ width: '75%', mx: 'auto' }} />
            <List>
              {secondSection.map((section) => (
                <SideBarButton
                  Icon={section.Icon}
                  text={section.text}
                  key={section.text}
                  isSelected={section.isSelected}
                  path={section.path}
                />
              ))}
            </List>
          </>
        )}
        <SignOutButton />
      </Drawer>
    </Box>
  );
};

export default SideBar;
