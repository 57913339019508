import React, { FC } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  visible: boolean;
}

const LoadingOverlay: FC<Props> = (props: Props) => {
  const { visible } = props;
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible}>
      <CircularProgress color="inherit" size={60} />
    </Backdrop>
  );
};

export default LoadingOverlay;
