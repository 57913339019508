import React, { FC } from 'react';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useAdminSearchClientByMessageQuery } from 'services/api/clients.api';
import { ClientWithLastMessage } from 'services/types/client';

interface Props {
  searchQuery: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectClient: (user: ClientWithLastMessage) => void;
}

const AdvancedSearch: FC<Props> = ({ searchQuery, open, setOpen, selectClient }) => {
  const { data } = useAdminSearchClientByMessageQuery(searchQuery);

  if (!open) {
    return null;
  }

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '100%',
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90vh',
        }}
      >
        <Box p={2} minWidth="250px">
          <Typography variant="h4">{data?.length ?? 0} resultados de búsqueda (máximo 100)</Typography>
          {data === undefined && <Typography variant="h4">Cargando...</Typography>}
        </Box>
        <Box overflow="scroll">
          {data?.map((client) => (
            <ListItemButton
              key={client.messageId}
              onClick={() => {
                setOpen(false);
                selectClient(client);
              }}
              sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pb: 2 }}
            >
              <Typography variant="body1">
                {client.firstName} {client.lastName} ({client.id}) - {client.businessUnitName} - {client.phone} -
                Mensaje {client.messageId}
              </Typography>
              <Typography variant="body2" color="grey" textOverflow="ellipsis" whiteSpace="nowrap">
                {client.messageText.substring(0, 200)}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      </Paper>
    </Modal>
  );
};

export default AdvancedSearch;
