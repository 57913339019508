import { Eval, EvalWithMessage } from 'services/types/evals';

import baseApi from './baseApi';

const evalsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postEval: build.mutation({
      query: (params: { messageId: number; score: number; comment?: string; desiredResponse?: string }) => ({
        url: '/admin/evals',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: Eval): Eval => response,
    }),
    editEval: build.mutation({
      query: (params: { evalId: number; score: number; comment?: string; desiredResponse?: string }) => ({
        url: `/admin/evals/${params.evalId}`,
        method: 'PATCH',
        body: params,
      }),
      transformResponse: (response: Eval): Eval => response,
    }),
    getAllEvals: build.query({
      query: () => ({
        url: '/admin/evals/all',
        method: 'GET',
      }),
      transformResponse: (response: EvalWithMessage[]): EvalWithMessage[] => response,
    }),
    getOneEvals: build.query({
      query: (params: { evalId: number }) => ({
        url: `/admin/evals/${params.evalId}`,
        method: 'GET',
      }),
      transformResponse: (response: Eval | null | undefined): Eval | null | undefined => response,
    }),
    deleteEval: build.mutation({
      query: (params: { evalId: number }) => ({
        url: `/admin/evals/delete/${params.evalId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: void): void => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostEvalMutation,
  useGetAllEvalsQuery,
  useGetOneEvalsQuery,
  useEditEvalMutation,
  useDeleteEvalMutation,
} = evalsApi;
