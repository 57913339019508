import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';
import { SignInResponse } from 'services/types/user';

const useCurrentUser = (): SignInResponse | null => {
  const user = useSelector<RootState, RootState['user']>((state) => state.user);
  return user;
};

export default useCurrentUser;
