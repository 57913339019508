import React, { FC } from 'react';

import { Typography } from '@mui/material';

import type { AnalysisHistoryMessage } from 'services/types/analysis';
import textToReactNodes from 'utils/textToReactNodes';

import MessageWrapper from './MessageWrapper';

interface Props {
  message: AnalysisHistoryMessage;
}

const CommonMessageComponent: FC<Props> = ({ message }: Props) => {
  return (
    <MessageWrapper
      caption={message.role === 'user' ? 'Usuario' : 'Asistente'}
      align={message.role === 'user' ? 'right' : 'left'}
    >
      <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
        {...textToReactNodes(message.content)}
      </Typography>
    </MessageWrapper>
  );
};

export default CommonMessageComponent;
