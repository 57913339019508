import React, { FC } from 'react';

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import type { SQLQueryResponse } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';

interface CellComponentProps {
  value: string | number | boolean | null | undefined;
}

const CellComponent: FC<CellComponentProps> = ({ value }) => {
  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }
  if (typeof value === 'object') {
    return <div>{JSON.stringify(value)}</div>;
  }
  if (typeof value === 'boolean') {
    return <div>{value ? 'true' : 'false'}</div>;
  }
  return <div>{value}</div>;
};

interface Props {
  queryData: SQLQueryResponse[];
}

const ExecuteSQLResponseComponent: FC<Props> = ({ queryData }) => {
  if (!queryData || queryData.length === 0) {
    return (
      <MessageWrapper caption="Respuesta Base de Datos" align="left">
        <div>Respuesta vacía</div>
      </MessageWrapper>
    );
  }
  const hasClientId = queryData.some((data) => data.id && typeof data.id === 'number');
  const rows: { id: string | number; link?: string }[] =
    queryData?.map((data, id) => ({
      ...data,
      id: hasClientId ? (data.id as number) : id,
      link: hasClientId ? `/clients/${data.id as number}` : undefined,
    })) ?? [];
  const columns =
    queryData && queryData.length > 0
      ? Object.keys(queryData[0]).map(
          (key): GridColDef<SQLQueryResponse> => ({
            field: key,
            headerName: key,
            // Min width in characters: max(10, max length of the column). Max width: 80. Approx 10 px per character
            width:
              12 *
              Math.min(
                Math.max(
                  10,
                  ...queryData.map(
                    (row) =>
                      (typeof row[key] === 'object'
                        ? JSON.stringify(row[key])
                        : (row[key] as string | number)?.toString()
                      )?.length || 0
                  )
                ),
                70
              ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderCell: (params: GridRenderCellParams<any, string>) => <CellComponent value={params.value} />,
            type: 'string',
          })
        )
      : [];

  if (hasClientId) {
    columns.push({
      field: 'link',
      headerName: 'Link',
      width: 100,
      renderCell: (params: GridRenderCellParams<SQLQueryResponse, string>) => (
        <a href={params.value} target="_blank" rel="noreferrer">
          Ver cliente
        </a>
      ),
    });
  }
  return (
    <MessageWrapper caption="Respuesta Base de Datos" align="left">
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
          },
          color: 'black',
        }}
        pageSizeOptions={[5, 10, 30, 100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: rows.length < 15 ? 100 : 5,
              page: 0,
            },
          },
        }}
      />
    </MessageWrapper>
  );
};

export default ExecuteSQLResponseComponent;
