import React, { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

interface Props {
  caption: string;
  align: 'left' | 'right' | 'center';
  children: ReactNode;
}

const MessageWrapper: FC<Props> = ({ children, caption, align }: Props) => (
  <Box justifyContent={align} display="flex">
    <Box
      sx={{
        bgcolor: align === 'left' ? '#f2f2f2' : '#E8F0FE',
        padding: 2,
        paddingTop: 1,
        borderRadius: 4,
        maxWidth: '100%',
      }}
    >
      <Typography variant="caption" fontWeight="bold">
        {caption}
      </Typography>
      {children}
    </Box>
  </Box>
);

export default MessageWrapper;
