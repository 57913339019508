import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const BlueSeparator = () => (
  <svg
    width="100%"
    preserveAspectRatio="none"
    height="2"
    viewBox="0 0 180 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.00003L180 1.00005" stroke="#47BCD8" strokeWidth="2" />
  </svg>
);

const customVerticalStepper = (steps: { title: string; subtitle: string; images: React.ReactNode }[]) => {
  return (
    <Box display="grid" px={2}>
      {steps.map((step, index) => (
        <Box
          display="flex"
          flexDirection={{ lg: 'row', sm: 'column', xs: 'column' }}
          alignItems="center"
          key={step.title}
        >
          <Box>
            <div
              style={{
                height: '52px',
                width: '52px',
                borderRadius: '50%',
                backgroundColor: '#dfe4ea',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div
                style={{
                  height: '32px',
                  width: '32px',
                  lineHeight: '60px',
                  borderRadius: '50%',
                  backgroundColor: '#F9D00A',
                  color: '#2C5889',
                  textAlign: 'center',
                }}
              >
                <Typography fontFamily="Roboto" fontSize="20px" lineHeight="30px" fontWeight="600">
                  {index + 1}
                </Typography>
              </div>
            </div>
          </Box>
          <Grid
            container
            p={4}
            py={8}
            style={{
              backgroundColor: '#E0F0F8',
              border: 'solid 0.1px #9FC6EA',
              borderRadius: '12px',
              margin: '8px',
            }}
          >
            <Grid item alignItems="center" display="grid" xs={12} md={6}>
              <Box>
                <Box width="fit-content" display="inline-grid" pb={2}>
                  <Typography
                    fontFamily="Roboto"
                    fontSize={{ lg: '22px', xs: '20px' }}
                    lineHeight="26px"
                    fontWeight="600"
                    textTransform="uppercase"
                    pb={2}
                  >
                    {step.title}
                  </Typography>
                  <BlueSeparator />
                </Box>
                <Typography
                  fontFamily="Jost"
                  fontWeight="regular"
                  fontSize={{ lg: '22px', xs: '18px' }}
                  lineHeight="25px"
                >
                  {step.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} pt={{ xs: 4, md: 0 }}>
              {step.images}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

const CRMLogos = () => {
  return (
    <Grid container spacing={4}>
      <Grid item display="grid" xs={12} md={6} justifyContent="center">
        <img alt="" src="/landing/crm/salesforce.png" />
      </Grid>
      <Grid item display="grid" xs={12} md={6} justifyContent="center">
        <img alt="" src="/landing/crm/hubSpot.png" />
      </Grid>
      <Grid item display="grid" xs={12} md={6}>
        <img alt="" src="/landing/crm/monday.svg" style={{ justifySelf: 'center', alignSelf: 'center' }} />
      </Grid>
      <Grid item display="grid" xs={12} md={6}>
        <img alt="" src="/landing/crm/c4c.png" style={{ justifySelf: 'center', alignSelf: 'center' }} />
      </Grid>
    </Grid>
  );
};

const How: FC = () => {
  return (
    <Grid container justifyContent="center" marginTop="35px" pt={0}>
      <Box textAlign="center">
        <Typography fontFamily="Roboto" fontSize={{ lg: '50px', xs: '35px' }} lineHeight="50px" fontWeight="600" py={8}>
          ¿Cómo funciona?
        </Typography>
        {customVerticalStepper([
          { title: 'Integra tu CRM', subtitle: 'Buscamos tus prospectos', images: CRMLogos() },
          {
            title: 'Entrega atención y asesoría de calidad',
            subtitle: 'Conversación natural, rápida y eficiente a toda hora. Con follow up inteligente',
            images: <img alt="" src="/landing/deliveryAndAttention.svg" width="80%" />,
          },
          {
            title: 'Devuelve a tu prospecto trabajado',
            subtitle: 'Con información clave, financiamiento e interés con probabilidad de compra',
            images: <img alt="" src="/landing/prospectoChat.svg" width="100%" />,
          },
        ])}
      </Box>
    </Grid>
  );
};

export default How;
